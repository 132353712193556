@use 'src/styles/typography';
@use 'src/styles/templates';

.itemNameWrapper {
  @extend %body-m;

  width: 42%;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  color: var(--contrast);
  transition: var(--transition);
}

.itemName {
  @extend %text-ellipsis;

  color: var(--contrast-transparent);
}

.wrapper {
  width: 100%;
  min-height: 32px;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
}

.rowWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.fieldWithButtonWrapper {
  display: flex;
  gap: 4px;
  max-width: 58%;
  width: 100%;
}

.itemWrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.comment {
  @extend .caption;

  color: var(--contrast);
  opacity: 0.5;
}
