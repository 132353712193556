@import 'src/styles/typography';
@import 'src/styles/variables';

[data-theme='light'] {
  --empty-card-background-color: var(--primary-2);
  --empty-card-border-color: #ffffff;
  --search-icon-background-color: transparent;
}

[data-theme='dark'] {
  --empty-card-background-color: var(--primary-3);
  --empty-card-border-color: var(--primary-2);
  --search-icon-background-color: rgba(126, 134, 158, 0.25);
}

.card {
  background-color: var(--empty-card-background-color);
  border-left: 1px solid var(--empty-card-border-color);
  border-right: 1px solid var(--empty-card-border-color);

  &Comparing {
    background-color: var(--comparing-background-2);
  }
}

.tooltip {
  &Inner {
    @extend .body-m;
  }

  &Heading {
    color: currentColor;
  }

  &Divider {
    margin-top: 4px;
    margin-bottom: 4px;
    background-color: var(--contrast);
    opacity: 0.1;
  }

  &Label {
    @extend .body-m-bold;

    margin-right: 4px;
    opacity: 0.5;
  }

  &Icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  &Row {
    margin: 4px 0;
    display: flex;
    gap: 4px;
    flex-flow: row;
    align-items: center;
  }
}

.loadingBlock {
  border-radius: 8px;
  border-left: 1px solid var(--background-2);
  border-right: 1px solid var(--background-2);
  animation: skeleton-loading 1s linear infinite alternate;
  z-index: 5;
}

.regularAttrLabel {
  color: var(--contrast);
  opacity: 0.7;
}

.regularAttrValue {
  color: var(--contrast);
}
