@use 'src/styles/typography';

.wrapper {
  width: max-content;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--primary-2);
  transition: background-color 0.2s;
  user-select: none;
  cursor: grab;

  &__grabbed {
    cursor: grabbing;
  }

  &:hover {
    opacity: 0.6;
  }
}

.text {
  @extend %body-l;

  color: var(--contrast);
  transition: var(--transition);
}
