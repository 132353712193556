@import 'palette';
@import 'fonts';
@import 'typography';
@import 'variables';
@import 'templates';
@import 'antd-global-styles';
@import 'highcharts';

:root {
  --animations-duration: 0.2s;
  --transition: var(--animations-duration);

  --table-border-radius: 4px;

  --button-border-radius: 4px;

  &[data-theme='light'] {
    --table-top-box-shadow: 0px -12px 32px rgba(22, 20, 82, 0.16);
  }

  &[data-theme='dark'] {
    --table-top-box-shadow: 0px -10px 20px #232323;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  -webkit-font-smoothing: antialiased; /* WebKit  */

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: var(--primary-2);
  }

  scrollbar-width: thin;
}

html {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
  min-height: 715px;
  position: relative;
}

#root {
  width: 100%;
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
