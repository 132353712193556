:root[data-theme='light'] {
  --upload-img-top-sheet__body: white;
  --upload-img-bottom-sheet__filed: var(--brand-1);
  --upload-img-bottom-sheet__body: white;
  --upload-img-bottom-sheet__back-sheet: var(--primary-3);
  --upload-img-bottom-sheet__body-corner: white;
  --upload-img-bottom-sheet__hand: white;
}

:root[data-theme='dark'] {
  --upload-img-top-sheet__body: var(--primary-1);
  --upload-img-bottom-sheet__filed: var(--primary-1);
  --upload-img-bottom-sheet__body: var(--brand-1);
  --upload-img-bottom-sheet__back-sheet: var(--primary-2);
  --upload-img-bottom-sheet__body-corner: var(--brand-2);
  --upload-img-bottom-sheet__hand: black;
}

.upload-img-top-sheet__body {
  fill: var(--upload-img-top-sheet__body);
  transition: var(--transition);
}

.upload-img-top-sheet__body-corner {
  fill: var(--primary-3);
  transition: var(--transition);
}

.upload-img-bottom-sheet__bg {
  fill: var(--primary-2);
  transition: var(--transition);
}

.upload-img-bottom-sheet__body {
  fill: var(--upload-img-bottom-sheet__body);
  transition: var(--transition);
}

.upload-img-bottom-sheet__back-sheet {
  fill: var(--upload-img-bottom-sheet__back-sheet);
  transition: var(--transition);
}

.upload-img-bottom-sheet__body-corner {
  fill: var(--upload-img-bottom-sheet__body-corner);
  transition: var(--transition);
}

.upload-img-bottom-sheet__filed {
  stroke: var(--upload-img-bottom-sheet__filed);
  transition: var(--transition);
}

.upload-img-bottom-sheet__arrow-top {
  stroke: var(--brand-1);
  transition: var(--transition);
}

.upload-img-bottom-sheet__hand {
  fill: var(--upload-img-bottom-sheet__hand);
  transition: var(--transition);
}
