:root[data-theme='light'] {
  --import-matching-page-contentWrapper-bg: white;
}

:root[data-theme='dark'] {
  --import-matching-page-contentWrapper-bg: var(--primary-1);
}

.pageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.wrapper {
  width: 100%;
  padding: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  padding-left: 24px;
  border-radius: 8px;
  background-color: var(--import-matching-page-contentWrapper-bg);
  display: flex;
  transition: var(--transition);
}

.preloaderCont {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  padding-top: 256px;
  justify-content: center;
  top: 0;
  left: 0;
  position: absolute;
}
