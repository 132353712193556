@use 'src/styles/templates';

.wrapper {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.comparingText {
  color: var(--contrast);
  margin: 0 8px;
  margin-bottom: 0;
  transition: var(--transition);
}

.iconsWrapper {
  max-width: 120px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconButton {
  @extend %ghost-button;

  width: 24px;
  height: 24px;
  margin-left: 24px;
  opacity: 1;
  transition: var(--transition);

  &:first-child {
    margin-left: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.icon {
  fill: var(--contrast);
  stroke: var(--contrast);
  transition: var(--transition);
}

.loaderAndAbortButtonWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 24px;
  opacity: 1;
  transition: var(--transition);

  &:hover {
    .exportLoader {
      opacity: 0.5;
    }

    .abortButton {
      opacity: 1;
    }
  }
}

.exportLoader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: var(--transition);
}

.abortButton {
  position: absolute;
  width: 24px;
  height: 24px;
  opacity: 0;
  background-color: transparent;
  transition: var(--transition);
}

.hidden {
  @media (max-width: 1679px) {
    display: none;
  }
}
