@import '../../../../styles/typography';
@import '../../../../styles/variables';

.container {
  position: absolute;
  transition: opacity var(--transition);
  opacity: 1;
  z-index: 5;
  overflow: hidden;
}

.card {
  background-color: var(--well-group-card-background-color);
  border-left: 1px solid var(--well-group-card-border-color);
  border-right: 1px solid var(--well-group-card-border-color);
}

.icon {
  width: 12px;
  height: 12px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.iconsContainer {
  display: flex;
  flex-flow: row nowrap;
}

.tooltip {
  &Inner {
    @extend .body-m;
  }

  &AttributesContainer {
    width: 100%;
  }

  &Heading,
  &SubHeading {
    color: currentColor;
  }

  &SubHeading {
    margin-top: 4px;
  }

  &Divider {
    margin-top: 4px;
    margin-bottom: 4px;
    background-color: var(--contrast);
    opacity: 0.1;
  }

  &Label {
    @extend .body-m-bold;

    margin-right: 4px;
    color: var(--contrast);
    opacity: 0.5;
  }

  &Icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: -28px;

    &Rig path {
      stroke: var(--contrast);
    }

    &Search {
      stroke: var(--contrast);

      circle {
        fill: var(--search-icon-background-color);
      }
    }
  }

  &Row {
    margin: 4px 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    position: relative;
  }

  &Attribute {
    &Container {
      display: flex;
      flex-flow: row wrap;
    }

    &Group {
      width: fit-content;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    &Row {
      margin: 0;
    }

    &Label {
      margin-right: 4px;
    }
  }
}

.draggingItem:nth-child(n) {
  opacity: 0.5;
}

.sortableDragging {
  z-index: 100;
}
