@use 'src/styles/templates';
@use 'src/styles/typography';

:root[data-theme='light'] {
  --modal-layout-bg: white;
  --modal-shadow: -14px 30px 20px rgba(0, 0, 0, 0.05);
}

:root[data-theme='dark'] {
  --modal-layout-bg: var(--primary-1);
  --modal-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.6);
}

.wrapper {
  min-width: 350px;
  width: max-content;
  padding: 24px 24px 34px;
  border-radius: 8px;
  background-color: var(--modal-layout-bg);
  box-shadow: var(--modal-shadow);
  overflow: hidden;
  position: relative;
}

.title {
  @extend %h2;

  color: var(--contrast);
  margin-bottom: 16px;
}

.closeButton {
  @extend %close-cross;

  top: 20px;
  right: 16px;
}

.loader {
  margin: auto;

  &Container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    visibility: hidden;
    background-color: transparent;
    -webkit-backdrop-filter: blur(0px);
    backdrop-filter: blur(0px);
    transition: var(--transition);

    &_visible {
      visibility: visible;
      background-color: var(--backdrop-transparent);
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
    }
  }
}
