@use 'src/styles/typography';

.text {
  @extend .body-m-bold;
  text-transform: capitalize;
}

.listItem {
  margin: 4px 0;
  padding-left: 32px;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  &Text {
    @extend .body-m;
    margin: 0;
  }
}

.loader,
.checkbox {
  width: 16px;
  height: 16px;
}
