.container {
  display: flex;
  flex-flow: row nowrap;
}

.button {
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 4px;
  background-color: var(--purple-color);
  outline: none;
  border: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &:active,
  &:focus {
    opacity: 0.8;
  }

  > button:disabled {
    background-color: transparent;
    outline: none;
    border: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.icon {
  width: 24px;
  height: 24px;

  &Table,
  &Reduce {
    @extend .icon;
  }

  &Table {
    path {
      stroke: var(--white-color);
    }
  }

  &Reduce {
    path {
      fill: var(--white-color);
    }
  }
}
