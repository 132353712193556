:root[data-theme='light'] {
  --table-bg-color: var(--white-color);
  --table-cell-border-color: var(--secondary-1);
  --compared-row-bg-color: rgba(58, 54, 219, 0.2);
}

:root[data-theme='dark'] {
  --table-bg-color: var(--primary-1);
  --table-cell-border-color: var(--secondary-2);
  --compared-row-bg-color: rgba(96, 92, 255, 0.4);
}

.wrapper {
  border-radius: 8px;
  position: relative;

  &Resizable {
    cursor: col-resize;
    user-select: none;
    -webkit-user-select: none;
  }

  &Editing {
    :global {
      .ant-table-thead > tr > th {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          border-bottom: none;

          &::before {
            display: none;
          }
        }

        &:nth-child(3) {
          text-align: center;
        }
      }

      .ant-table-tbody > tr > td {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          border-bottom: none;
        }
      }
    }
  }

  :global {
    .ant-table {
      padding: 0 !important;
    }

    .ant-table-container:before,
    .ant-table-container:after {
      display: none;
    }

    .ant-table-thead > tr {
      th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
        &::before {
          background-color: var(--table-cell-border-color);
          top: 16px !important;
        }

        &:hover {
          &::before {
            background-color: var(--table-cell-border-color) !important;
          }
        }
      }
    }

    .ant-table-cell-ellipsis {
      white-space: normal;
    }

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background: var(--table-bg-color) !important;
    }

    .ant-table-thead th.ant-table-column-sort::before {
      background-color: var(--table-cell-border-color) !important;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover,
    .ant-table,
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th,
    td.ant-table-column-sort {
      background: transparent;

      &:hover {
        background: transparent;
      }
    }

    .ant-table,
    .ant-table-thead > tr > th,
    td.ant-table-column-sort {
      padding: 8px;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover,
    .ant-table-tbody > tr > td {
      padding: 0px;
      height: 36px;
    }

    .ant-table-row {
      &.Compare {
        background: var(--compared-row-bg-color);
      }
    }

    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      border: none;
    }

    .ant-table-thead > tr > th {
      color: var(--contrast);
      vertical-align: top;
    }

    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: var(--brand-1);
    }
  }
}
