.table {
  width: 100%;
  height: 100%;
  position: relative;
  border-top-left-radius: var(--table-border-radius);
  border-bottom-left-radius: var(--table-border-radius);
  z-index: 21;

  &Expanded {
    margin-top: 0;
    overflow: visible;
  }

  &Expanded > &DataContainer {
    height: auto;
    margin-top: 0;
    display: flex;
    flex-flow: column;
    box-shadow: var(--table-top-box-shadow);
    z-index: 5;
  }

  &DataContainer {
    margin-top: 8px;
    padding-top: 0;
    position: relative;
    background-color: var(--primary-1);
    border-top-left-radius: var(--table-border-radius);
    border-bottom-left-radius: var(--table-border-radius);
  }
}
