.label {
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 100px;
  border: 1px solid var(--brand-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &__selected {
    &::after {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background-color: var(--brand-1);
    }
  }

  &:focus-visible {
    outline: 1px solid var(--brand-1);
  }
}

.input {
  display: none;
}
