[data-theme='light'] {
  --well-info-header-background-color: transparent;
}

[data-theme='dark'] {
  --well-info-header-background-color: var(--primary-2);
}

.container {
  width: 600px;
}

.header {
  padding: 16px;
  display: flex;
  background-color: var(--well-info-header-background-color);
}

.tabs {
  margin: auto 0;
}

.descriptions {
  padding: 16px;
}

.tabContent {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;

  &Inner {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.edit {
  &Button {
    width: 28px;
    height: 28px;
    margin-left: auto;
  }

  &Icon {
    path {
      stroke: var(--contrast);
    }
  }
}

.loader {
  margin: auto;
}
