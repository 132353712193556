@use 'src/styles/typography';

:root[data-theme='light'] {
  --upload-plan-modal-drag-area-bg: var(--primary-1);
  --upload-plan-modal-create-area-bg: var(--primary-1);
}

:root[data-theme='dark'] {
  --upload-plan-modal-drag-area-bg: var(--primary-2);
  --upload-plan-modal-create-area-bg: var(--primary-2);
}

.text {
  @extend %body-m;

  color: var(--contrast);
}

.createArea {
  max-width: 300px;
  width: 100%;
  padding: 24px;
  margin-top: 24px;
  border-radius: 8px;
  background-color: var(--upload-plan-modal-create-area-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoWrapper {
  display: flex;
  align-items: center;
}

.iconWrapper {
  min-width: 60px;
  height: 60px;
  margin-right: 16px;
  background-color: var(--brand-1);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus {
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 10px;
    height: 1px;
    background-color: white;
    position: absolute;
  }

  &::after {
    transform: rotate(90deg);
  }
}
