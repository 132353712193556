@import 'src/styles/templates';

.pageWrapper {
  width: 100%;
  max-height: 100%;
  flex-grow: 1;
  overflow-y: auto;
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.approachesList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.newApproachButtonWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.newApproachTooltipIcon {
  @extend %ghost-button;

  height: 24px;
}
