.contentWrapper {
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.formFetchingLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.loaderWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
