@use 'src/styles/typography';

$error: rgba(235, 87, 87, 0.7);

.uploadArea {
  width: 300px;
  min-height: 275px;
  padding: 24px 24px 40px;
  margin-top: 16px;
  margin-bottom: 36px;
  border-radius: 8px;
  background-color: var(--upload-plan-modal-drag-area-bg);
  border: 1px dashed var(--brand-1);
  transition: var(--transition);

  &__error {
    border-color: $error;
  }
}

.plus {
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 10px;
    height: 1px;
    background-color: white;
    position: absolute;
  }

  &::after {
    transform: rotate(90deg);
  }
}

.uploadImg {
  transform: scale(1);
  margin-bottom: 32px;
  transition: var(--transition);

  &__active {
    transform: scale(1.1);
  }
}

.contentWrapper {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  position: relative;
}

.uploadButtonAndTextWrapper {
  position: absolute;
  top: 0;

  &__hidden {
    opacity: 0;
  }

  &__visible {
    opacity: 1;
  }
}

.text {
  @extend %body-m-bold;

  color: var(--brand-1);
  text-align: center;
  font-size: 18px;

  &__error {
    color: $error;
  }
}

.hiddenInput {
  display: none;
}
