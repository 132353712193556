.container {
  :global {
    .ant-typography {
      font-family: Inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: var(--contrast);
      margin: 0;
    }
  }
}
