:root[data-theme='light'] {
  --checkbox-border-color: var(--brand-1);
}

:root[data-theme='dark'] {
  --checkbox-border-color: rgba(96, 92, 255, 0.5);
}

.ant-checkbox-wrapper {
  color: var(--contrast);
  transition: var(--transition);
}

.ant-checkbox-wrapper-disabled,
.ant-checkbox-disabled,
.ant-checkbox-disabled + span {
  cursor: default !important;
}

.ant-checkbox-wrapper-disabled {
  opacity: 0.5;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
}

.ant-checkbox + span {
  color: var(--caontrast);
  padding-left: 16px;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  border: 1px solid var(--checkbox-border-color) !important;
}

.ant-checkbox-inner {
  border: 1px solid var(--checkbox-border-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--brand-1);
  border: 1px solid var(--checkbox-border-color);
}

.ant-checkbox-inner {
  border-radius: 4px;

  &::after {
    width: 5px;
    height: 8px;
  }
}
