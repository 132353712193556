@use 'src/styles/typography';

:root[data-theme='light'] {
  --inactive-button-bg-color: var(--white-color);
  --inactive-button-border-color: var(--primary-2);
  --active-button-text-color: var(--white-color);
  --inactive-button-text-color: var(--contrast-transparent);
}

:root[data-theme='dark'] {
  --inactive-button-bg-color: var(--primary-1);
  --inactive-button-border-color: var(--primary-1);
  --active-button-text-color: var(--contrast);
  --inactive-button-text-color: var(--secondary-1);
}

.container {
  display: flex;

  span {
    padding: 0;

    &:first-child button {
      border-radius: 4px 0 0 4px;
    }

    &:last-child button {
      border-radius: 0 4px 4px 0;
    }
  }

  span > button {
    @extend .switchButton;
  }
}

.switchButton {
  @extend %body-m;

  height: 28px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--inactive-button-border-color);
  background: var(--inactive-button-bg-color);
  color: var(--inactive-button-text-color);
  transition: var(--transition);
  user-select: none;

  &.active {
    background: var(--brand-1);
    border: 1px solid var(--brand-1);
    color: var(--active-button-text-color);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
}

.buttonWithIndicator {
  padding: 0 8px 0 16px;
  transition: all var(--transition), padding 0s;
}

.countIndicator {
  @extend .button-m;

  font-size: 12px;
  color: white;
  width: 34px;
  min-height: 20px;
  max-height: 20px;
  margin-left: 4px;
  padding: 0 4px;
  border-radius: 16px;
  background-color: var(--red-color);
}
