@import 'src/styles/typography';
@import 'src/styles/templates';

[data-theme='light'] {
  --summary-sidebar-background-color: var(--white-color);
}

[data-theme='dark'] {
  --summary-sidebar-background-color: var(--primary-1);
}

.container {
  width: 658px;
  position: relative;
  background-color: var(--summary-sidebar-background-color);
  box-shadow: -25px 4px 50px rgba(1, 0, 47, 0.25);
  overflow: hidden;
}

.header {
  padding: 16px 16px 10px 24px;
  display: flex;
  flex-flow: row nowrap;
  background-color: var(--summary-sidebar-background-color);

  &Title {
    @extend .h1;
    @extend %text-ellipsis;

    color: var(--contrast);
  }
}

.close {
  &Button {
    width: 32px;
    height: 32px;
    margin: auto 0 auto auto;
  }

  &Icon {
    width: 24px;
    height: 24px;

    path {
      stroke: var(--contrast);
    }
  }
}

.yearPicker {
  width: 109px;
  margin-left: 24px;
}
