@use 'src/styles/typography';
@use 'src/styles/templates';

.errorText {
  @extend .caption;
  @extend %text-ellipsis;

  position: absolute;
  max-width: 100%;
  color: var(--red-color);
}
