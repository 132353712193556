.container {
  height: 100%;
  position: relative;
}

.list {
  width: 100%;
  height: 100%;
}

.addButton {
  margin: 24px 16px 16px;
  background-color: transparent;
}

.sortableWellCard {
  margin-bottom: 16px;
}

.wellIcon {
  width: 16px;
  height: 16px;
  opacity: 0.5;
}
