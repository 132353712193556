@use 'src/styles/typography';

:root[data-theme='light'] {
  --new-well-progress-bar-bg-color: var(--primary-2);
}

:root[data-theme='dark'] {
  --new-well-progress-bar-bg-color: var(--primary-1);
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.progressBar {
  width: 332px;
}

.text {
  @extend %body-m;

  color: var(--contrast);
  transition: var(--transition);
}
