@use 'src/styles/typography';
@use 'src/styles/templates';

.itemWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.itemName {
  @extend .body-m;

  max-width: 200px;
  margin-right: 4px;
  color: var(--contrast-transparent);
  transition: var(--transition);
}

.firstWrapper {
  max-width: 1032px;
  width: 55.3%;
}

.firstElement {
  max-width: 832px;
  width: 100%;
}

.secondElement {
  width: 216px;
}
