@import 'src/styles/templates';
@import 'src/styles/typography';

:root {
  --descriptions-label-column-width: 'auto';
  --descriptions-value-column-width: 'auto';
  --descriptions-label-level: 0;
}

.container {
  padding: 8px 0;
  display: flex;
  flex-flow: column;
}

.description {
  display: grid;
  align-items: center;
  grid-template-areas: 'label value';
  grid-template-columns: var(--descriptions-label-column-width) var(--descriptions-value-column-width);

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &Vertical {
    display: flex;
    flex-flow: column;

    .descriptionLabel {
      margin-bottom: 4px;
    }
  }

  &Label,
  &Value {
    @extend .body-m;

    color: var(--contrast);
  }

  &Label {
    padding-left: calc(var(--descriptions-label-level) * 16px);
    padding-right: 4px;
    grid-area: label;
    color: var(--contrast-transparent);
  }

  &Value {
    grid-area: value;
  }
}
