.contentContainer {
  max-height: 100%;
  height: 100%;
  padding: 8px 8px 16px 24px;
  overflow-y: auto;
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
