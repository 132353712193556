.wrapper {
  height: 100%;
  padding-left: 64px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  column-gap: 8px;
}
