@use 'src/styles/typography';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Text {
    @extend %h3;
    color: var(--contrast);
  }

  &FixButton {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--contrast);
    opacity: 0.5;

    &Fixed {
      opacity: 1;
    }
  }
}
