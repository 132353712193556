:root[data-theme='light'] {
  --new-well-regular-select-border: var(--primary-3);
  --new-well-regular-select-hover-border-color: rgba(101, 98, 245, 0.7);
  --new-well-regular-select-bg: transparent;
  --select-option-hover-color: rgba(58, 54, 219, 0.2);
}

:root[data-theme='dark'] {
  --new-well-regular-select-border: var(--primary-2);
  --new-well-regular-select-hover-border-color: rgba(69, 65, 201, 0.7);
  --new-well-regular-select-bg: var(--primary-2);
  --select-option-hover-color: rgba(96, 92, 255, 0.2);
}

.wrapper {
  :global(.ant-select *) {
    transition: var(--transition);
  }

  :global(.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
      .ant-select-selector) {
    border-color: var(--red-color) !important;
  }

  :global(.ant-select-arrow) {
    right: 4px;
  }

  :global(.ant-select-clear) {
    right: 10px;

    svg > path {
      fill: var(--contrast);
      opacity: 0.5;
    }
  }

  :global(.ant-select-focused) {
    box-shadow: 0 4px 4px rgba(58, 54, 219, 0.25);
    z-index: 3;
  }

  :global(.ant-select-disabled) {
    border: 1px solid var(--new-well-regular-select-border);
    background-color: var(--new-well-regular-select-bg);

    &:hover {
      border: 1px solid var(--new-well-regular-select-border);
      background-color: var(--new-well-regular-select-bg);
    }
  }

  :global(.ant-select-disabled .ant-select-selector) {
    opacity: 0.5;
    transition: var(--transition);
  }

  :global(.ant-select-disabled .ant-select-arrow) {
    transition: var(--transition);
  }

  :global(.ant-select-disabled .ant-select-selector .ant-select-selection-placeholder) {
    transition: var(--transition);
  }
}

.select {
  color: var(--contrast);
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: 1px solid var(--new-well-regular-select-border);
  background-color: var(--new-well-regular-select-bg);
  transition: var(--transition);

  &__error {
    border-color: var(--red-color);
  }

  &:hover {
    border-color: var(--new-well-regular-select-hover-border-color);
  }

  &:focus-within {
    border: 1px solid var(--brand-2);
  }
}
