.wrapper {
  display: flex;
  align-items: center;

  .buttonsContainer {
    display: flex;
    align-items: center;
    margin-left: 24px;
    gap: 16px;

    .button {
      height: 28px;
      white-space: nowrap;
    }

    .iconButton {
      cursor: pointer;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--contrast);
    }
  }
}
