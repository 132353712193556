.resizeLine {
  height: 100%;
  width: 1px;
  background: var(--brand-1);
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 10000;
  display: none;
}
