@import 'src/styles/typography';
@import 'src/styles/templates';

:root[data-theme='light'] {
  --new-well-section-bg-color: rgba(255, 255, 255, 0.5);
}

:root[data-theme='dark'] {
  --new-well-section-bg-color: rgba(54, 65, 83, 0.5);
}

.wrapper {
  padding: 24px 0 16px;
  padding-left: 20px;
  background-color: var(--new-well-section-bg-color);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: flex-start;

  &:focus {
    outline: none;
  }

  &__active {
    opacity: 0;
  }

  &__overlay {
    position: relative;
    z-index: 1;
    cursor: grabbing;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      cursor: grabbing;
    }
  }
}

.contentWrapper {
  display: flex;
  align-items: center;
}

.inputsWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  padding-top: 24px;

  @media (max-width: 1679px) {
    gap: 8px;
  }
}

.deleteButton {
  padding: 4px;
  margin-right: 4px;
}

.splitButton {
  padding: 0;
  margin-right: 4px;
}

.text {
  @extend .h3;

  width: 200px;
  color: var(--contrast);
  transition: var(--transition);

  &__combo {
    width: max-content;
    margin-right: 16px;
  }
}

.mainElement {
  width: 400px;
}

.dotsIcon {
  color: var(--contrast);
  cursor: grab;
  position: relative;
  margin-right: 4px;

  &:active {
    cursor: grabbing;
  }
}

.field {
  width: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
