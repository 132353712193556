@import 'src/styles/typography';
@import 'src/styles/templates';

.settingsItem {
  min-height: 40px;
  display: flex;
  flex-flow: row;
  align-items: center;

  &Label {
    @extend .body-m;

    color: var(--contrast);

    &Wrapper {
      width: 100%;
      margin: auto 0;
    }
  }

  &Drag {
    @extend %grabbing;

    width: 24px;
    height: 100%;
    margin-right: 8px;
    display: flex;
  }

  &Switch {
    margin-right: 8px;

    &:focus {
      box-shadow: none;
    }
  }
}

.moveIcon {
  width: 24px;
  height: 24px;
  margin: auto;
  stroke: var(--contrast);
}
