@use 'src/styles/typography';

:root[data-theme='light'] {
  --date-picker-bg-color: var(--white-color);
  --date-picker-border-color: rgba(170, 195, 250, 1);
  --border-top-color: var(--primary-2);
}

:root[data-theme='dark'] {
  --date-picker-bg-color: var(--primary-3);
  --date-picker-border-color: var(--primary-3);
  --border-top-color: var(--secondary-2);
}

.container {
  border-top: 1px solid var(--border-top-color);
}

.hint {
  color: var(--contrast);
  opacity: 0.6;
  margin: 16px 0;
}

.filtersContainer {
  display: flex;
  align-items: center;

  .dash {
    margin: auto 4px 0;
    line-height: 32px;
    color: var(--contrast);
  }

  :global {
    .ant-picker {
      width: 100%;
      padding: 0;
      border: 1px solid var(--date-picker-border-color);
      border-radius: 4px;
      display: flex;
      background: var(--date-picker-bg-color);
      padding: 4px 4px 4px 16px;
    }
    .ant-picker-focused {
      box-shadow: none;
    }

    .ant-picker-input > input {
      color: var(--contrast);
      font-family: 'Inter';
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;

      &::placeholder {
        color: var(--contrast);
        opacity: 0.5;
      }
    }

    .ant-picker-suffix {
      color: var(--contrast);
    }
  }
}

.checkItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.bottomButtonsContainer {
  display: flex;
  align-items: center;
  margin-top: 36px;
  padding: 8px 0;

  .button {
    &:first-child {
      margin-right: 8px;
    }
  }
}
