@import 'src/styles/typography';

.container {
  @extend .body-m;

  display: flex;
  flex-flow: column;
  color: var(--contrast);
}

.block {
  padding-right: 16px;
  padding-left: 24px;
}

.contentWrapper {
  @extend .block;

  overflow-y: auto;
}

.footer {
  @extend .block;

  width: 100%;
  margin-top: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-flow: row nowrap;

  .button {
    width: 50%;
    color: var(--white-color);

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.search {
  margin: 8px 0 16px;
  background-color: var(--background-1);
}

.loader {
  margin: auto;

  &Container {
    width: calc(100% - 16px * 2);
    position: absolute;
    top: 48px;
    bottom: 0;
    display: flex;
    z-index: 40;
  }
}
