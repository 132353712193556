:root[data-theme='light'] {
  --upload-modal-area-bg__table-sheet-bg: white;
  --upload-modal-area-bg__1st-sheet: var(--primary-2);
  --upload-modal-area-bg__3rd-sheet: var(--primary-2);
  --upload-modal-area-bg__cloud: var(--primary-3);
  --upload-modal-area-bg__arrow: var(--primary-1);
}

:root[data-theme='dark'] {
  --upload-modal-area-bg__table-sheet-bg: var(--primary-2);
  --upload-modal-area-bg__1st-sheet: var(--primary-1);
  --upload-modal-area-bg__3rd-sheet: var(--brand-1);
  --upload-modal-area-bg__cloud: var(--brand-1);
  --upload-modal-area-bg__arrow: var(--primary-2);
}

.upload-modal-area-bg__table-sheet-bg {
  fill: var(--upload-modal-area-bg__table-sheet-bg);
}

.upload-modal-area-bg__table-sheet-sheet,
.upload-modal-area-bg__table-sheet-bg {
  stroke: var(--brand-1);
}

.upload-modal-area-bg__1st-sheet {
  fill: var(--upload-modal-area-bg__1st-sheet);
}

.upload-modal-area-bg__2nd-sheet {
  fill: var(--brand-2);
}

.upload-modal-area-bg__3rd-sheet {
  fill: var(--upload-modal-area-bg__3rd-sheet);
}

.upload-modal-area-bg__plus-sheet-corner {
  fill: var(--brand-1);
}

.upload-modal-area-bg__plus-sheet-plus,
.upload-modal-area-bg__plus-sheet-corner {
  stroke: var(--brand-1);
}

.upload-modal-area-bg__plus-sheet-border {
  stroke: var(--brand-1);
}

.upload-modal-area-bg__arrow {
  fill: var(--upload-modal-area-bg__arrow);
}

.upload-modal-area-bg__cloud {
  fill: var(--upload-modal-area-bg__cloud);
}
