.container {
  padding: 16px 8px 0 24px;
}

.hint {
  margin-bottom: 16px;
  color: var(--contrast);
  opacity: 0.5;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
