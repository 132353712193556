[data-theme='light'] {
  --icon-button-background-color: var(--white-color);
}

[data-theme='dark'] {
  --icon-button-background-color: var(--primary-1);
}

.container {
  width: 32px;
  height: 32px;
  padding: 4px;
  display: flex;
  border-radius: 4px;
}

.button {
  @extend .container;

  background-color: var(--icon-button-background-color);
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.iconWrapper {
  width: 100%;
  height: 100%;
  display: flex;

  > svg {
    width: 100%;
    height: 100%;
    margin: auto;
    object-fit: contain;
  }
}

.loader {
  width: 100%;
  height: 100%;
  margin: auto;
}
