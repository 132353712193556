:root[data-theme='light'] {
  --skeleton-loading-background-color-start: rgba(101, 98, 245, 0.02);
  --skeleton-loading-background-color-end: rgba(101, 98, 245, 0.06);
  --insertion-highlight-start-color: rgba(58, 54, 219, 0.2);
}

:root[data-theme='dark'] {
  --skeleton-loading-background-color-start: rgba(255, 255, 255, 0.01);
  --skeleton-loading-background-color-end: rgba(255, 255, 255, 0.05);
  --insertion-highlight-start-color: rgba(96, 92, 255, 0.2);
}

@mixin multiline-text-ellipsis($lines-count: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines-count;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--skeleton-loading-background-color-start);
  }
  100% {
    background-color: var(--skeleton-loading-background-color-end);
  }
}

@keyframes insertion-highlight {
  0% {
    background-color: var(--insertion-highlight-start-color);
  }

  100% {
    background-color: var(--row-background-color);
  }
}
