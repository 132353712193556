:root[data-theme='light'] {
  --header-grid-line-color: var(--secondary-1);
  --lines-blend-mode: multiply;
}

:root[data-theme='dark'] {
  --header-grid-line-color: var(--secondary-2);
  --lines-blend-mode: none;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.dataColumns {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;

  &Wrapper {
    width: 100%;
    height: 100%;
  }
}

.title {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 8px 4px;
  text-align: center;
  color: var(--contrast);

  &Wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;

    &::after {
      content: '';
      width: 1px;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: var(--header-grid-line-color);
      z-index: 10;
      mix-blend-mode: var(--lines-blend-mode);
    }
  }
}
