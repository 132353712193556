@import 'src/styles/typography';

%border {
  content: '';
  width: 1px;
  position: absolute;
  top: 0;
  display: block;
  background-color: var(--brand-1);
}

.year {
  @extend .caption;

  padding: 0 2px;
  color: var(--contrast);

  &Container {
    width: 100%;
    height: 100%;
    padding-bottom: 2px;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    user-select: none;
  }
}

.month {
  @extend .description;

  margin: 0 auto;
  padding: 0 2px;
  text-align: center;
  color: var(--contrast);

  &Wrapper {
    width: 100%;
    min-width: 16px;

    &::after {
      @extend %border;

      height: 10px;
    }

    &:first-of-type {
      &::before {
        @extend %border;

        height: 40px;
      }
    }
  }
}

.monthsContainer {
  margin-bottom: auto;
  display: flex;
  flex-flow: row nowrap;
}
