:root[data-theme='light'] {
  --pad-well-card-bg-color: var(--white-color);
}

:root[data-theme='dark'] {
  --pad-well-card-bg-color: var(--primary-2);
}

.TabWithWells {
  &PadWellsWrapper {
    margin: 0 0 24px 24px;
    padding: 24px 24px 24px 0;
  }
}

.wellCard {
  background-color: var(--pad-well-card-bg-color);
}
