@use 'src/styles/typography';

:root[data-theme='light'] {
  --drilling-rig-sidebar-control-bar-bg-color: transparent;
}

:root[data-theme='dark'] {
  --drilling-rig-sidebar-control-bar-bg-color: var(--primary-2);
}

.controlBar {
  height: 60px;
  width: 100%;
  background: var(--drilling-rig-sidebar-control-bar-bg-color);
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .editButton {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .editIcon {
    color: var(--contrast);
  }
}

.editButtonsContainer {
  display: flex;
}

.closeButton,
.saveButton {
  padding: 4px 16px;
  height: 28px;
  display: flex;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    opacity: 0.8;
  }
}

.closeButton {
  background: var(--red-color);
  margin-right: 8px;
}

.saveButton {
  background: var(--green-color);
}
