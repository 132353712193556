:root[data-theme='light'] {
  --pad-collapse-bg-color: var(--primary-2);
}

:root[data-theme='dark'] {
  --pad-collapse-bg-color: rgba(26, 32, 44, 0.72);
}

.container {
  margin-bottom: 8px;

  &:last-of-type {
    margin: 0;
  }

  :global {
    .ant-collapse-item {
      background: var(--pad-collapse-bg-color);
      border-radius: 8px !important;
      padding: 16px 4px;
      border: none;

      .ant-collapse-header {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0;
        cursor: default;

        .ant-collapse-header-text {
          order: 1;
        }

        .ant-collapse-expand-icon {
          width: 16px;
          height: 16px;
          margin-right: 16px;
          display: flex;
          align-items: center;
          order: 2;
          cursor: pointer;
        }

        .ant-collapse-extra {
          order: 3;
          margin: 0;
          flex: 1;
        }
      }

      .ant-collapse-content-box {
        margin-top: 16px;
      }
    }
  }
}

.expandIcon {
  width: 10px;
  height: 16px;
  margin: auto;
  color: var(--contrast);
  transition: var(--transition);
  fill: var(--contrast);

  &Active {
    transform: rotate(180deg);
  }

  &Disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
