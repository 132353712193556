.tabsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.additionalTabComponentWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}
