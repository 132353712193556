.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}
