.wrapper {
  width: 100%;

  :global {
    .ant-select-selector {
      width: 100%;
    }

    .ant-select {
      display: block;
      color: var(--contrast);
      position: static;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
    }

    .ant-select-clear {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0;
      color: var(--contrast);
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }

    .ant-select-single.ant-select-open .ant-select-selection-item {
      color: var(--contrast);
    }
  }
}
