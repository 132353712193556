@import 'src/styles/typography';

:root[data-theme='light'] {
  --overlay-backgroundColor: rgba(0, 0, 0, 0.1);
}

:root[data-theme='dark'] {
  --overlay-backgroundColor: rgba(0, 0, 0, 0.3);
}

.pageContainer {
  overflow: hidden;
  position: relative;
}

.loader {
  display: grid;
  place-items: center;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-backgroundColor);
}

.contentContainer {
  width: 100%;
  height: calc(100% - 38px);
  display: flex;
  column-gap: 16px;
  padding: 16px;
  justify-content: space-between;
}
