@use 'src/styles/templates';
@use 'src/styles/typography';

:root[data-theme='light'] {
  --switcher-text: var(--brand-1);
  --switcher-text-compact-incative: var(--white-color);
  --switcher-without-stroke-color: #6562f5;
  --inactive-textWrapper-bg-color: var(--white-color);
  --inactive-textWrapper-border-color: var(--primary-2);
  --inactive-textWrapper-color: #06152b;
  --active-textWrapper-color: var(--white-color);
}

:root[data-theme='dark'] {
  --switcher-text: var(--contrast);
  --switcher-text-compact-incative: var(--contrast);
  --switcher-without-stroke-color: rgba(255, 255, 255, 0.5);
  --inactive-textWrapper-bg-color: #28313f;
  --inactive-textWrapper-border-color: #28313f;
  --inactive-textWrapper-color: var(--white-color);
  --active-textWrapper-color: var(--white-color);
}

.wrapper {
  @extend %ghost-button;
  width: 184px;
  height: 28px;
  border-radius: 4px;
  background-color: var(--inactive-textWrapper-bg-color);
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  transition: var(--transition);

  &::after {
    content: '';
    width: 50%;
    height: 100%;
    background-color: var(--brand-1);
    position: absolute;
    transition: var(--transition);
    top: 0px;
    left: 0px;
  }

  &__compact {
    width: 44px;
    height: 24px;
    border-radius: 100px;
    background-color: var(--primary-2);

    .textWrapper {
      border: none;
    }

    &::after {
      width: 20px;
      height: calc(100% - 4px);
      border-radius: 100%;
      background-color: white;
      top: 2px;
      left: 2px;
    }
  }

  &__compactActive {
    background-color: var(--primary-3);
    @if (var(--dark)) {
      background-color: var(--brand-1);
    }
  }

  &__vertical {
    width: 24px;
    height: 44px;
    flex-direction: column;
    justify-content: center;

    &::after {
      width: calc(100% - 4px);
      height: 20px;
      top: 2px;
      left: 2px;
    }
  }

  &__verticalActive {
    &::after {
      top: 50%;
    }
  }

  &__active {
    &::after {
      left: 50%;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.textWrapper {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border: 1px solid var(--inactive-textWrapper-border-color);

  &__vertical {
    width: 100%;
    height: 50%;
  }

  &__selected {
    border: none;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:last-child {
    & .icon__withoutStroke {
      color: var(--switcher-without-stroke-color);
    }

    & .icon__withoutStroke.icon__active {
      color: var(--brand-1);
    }
  }
}

.text {
  @extend %body-m;
  @extend %text-ellipsis;

  color: var(--inactive-textWrapper-color);
  margin-bottom: 0;
  position: absolute;
  z-index: 2;
  transition: var(--transition);

  &__hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -10;
    transition: none;
  }

  &__active {
    color: var(--active-textWrapper-color);
    opacity: 1;
  }

  &__compact {
    @extend %body-xxs;
    color: var(--switcher-text);
    opacity: 1;
  }

  &__compactInactive {
    color: var(--switcher-text);
    opacity: 1;
  }

  &__compactLightInactive {
    color: var(--switcher-text-compact-incative);
  }

  &__compactActive {
    color: var(--brand-1);
    opacity: 1;
  }
}

.compactIconWrapper {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  transition: var(--transition);

  &__hidden {
    opacity: 0;
    z-index: -10;
    visibility: hidden;
    transition: none;
  }
}

.icon {
  fill: var(--white-color);
  stroke: var(--white-color);
  color: var(--white-color);

  &__active {
    fill: var(--brand-1);
    stroke: var(--brand-1);
    color: var(--brand-1);
  }

  &__withoutStroke {
    stroke: none;
    fill: none;
  }
}
