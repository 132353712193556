@use 'src/styles/templates';
@use 'src/styles/typography';
@use 'src/styles/variables';

:root[data-theme='light'] {
  --well-card-background-color: #ffffff;
  --well-card-border-color: var(--primary-2);
  --well-group-card-background-color: var(--primary-2);
  --well-group-card-border-color: #ffffff;
  --empty-pad-loading-background-color-start: rgba(0, 0, 0, 0.01);
  --empty-pad-loading-background-color-end: rgba(0, 0, 0, 0.05);
}

:root[data-theme='dark'] {
  --well-card-background-color: var(--primary-1);
  --well-card-border-color: var(--primary-3);
  --well-group-card-background-color: var(--primary-3);
  --well-group-card-border-color: var(--primary-1);
  --empty-pad-loading-background-color-start: rgba(255, 255, 255, 0.01);
  --empty-pad-loading-background-color-end: rgba(255, 255, 255, 0.05);
}

.dataItem {
  position: absolute;
  z-index: 10;
  touch-action: none;
  opacity: 1;
  transition: opacity var(--transition);
}

.wellsGroupCard {
  background-color: var(--well-group-card-background-color);
  border-left: 1px solid var(--well-group-card-border-color);
  border-right: 1px solid var(--well-group-card-border-color);
}

.stageCard {
  &Comparing:nth-child(n) {
    background-color: var(--comparing-background-2);
    border-color: var(--comparing-background);
    color: var(--white-color);
  }

  &InnerComparing:nth-child(n) {
    color: var(--white-color);
    opacity: 1;
  }

  &HeaderComparing {
    color: var(--white-color);
  }
}

.wellCard {
  height: 100%;
  left: 2px;
  background-color: var(--well-card-background-color);
  border-left: 1px solid var(--well-card-border-color);
  border-right: 1px solid var(--well-card-border-color);
  cursor: pointer;
  box-shadow: none;

  &.wellCard {
    width: calc(100% - 4px);
  }

  &Comparing:nth-child(n) {
    background-color: var(--comparing-background);
  }
}

.wellIcon {
  min-width: 16px;
  height: 16px;
  margin-left: 4px;
}

.loadingBlock {
  @extend .dataItem;

  border-radius: 8px;
  border-left: 2px solid var(--well-group-card-border-color);
  border-right: 2px solid var(--well-group-card-border-color);
  animation: skeleton-loading 1s linear infinite alternate;
  z-index: 7;
}

.padCard:nth-child(n) {
  z-index: 7;
}

.padCard {
  &_fixed {
    height: calc(100% - 2 * 4px);
    margin-top: 4px;
  }

  &_fixed &Content {
    width: auto;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
  }

  &_inComparingRig:nth-child(n) {
    @extend .padCard;

    border-left: none;
    border-right: none;
  }
}

.draggingItem {
  opacity: 0.5;
}

.dataItem {
  &.draggableGhost {
    top: -10px;
    z-index: 100;
    will-change: transform;
  }

  &.draggableWellGhost {
    @extend .draggableGhost;

    width: 144px;
    height: 32px;

    > .wellCard {
      width: 100%;
      height: 100%;
      background-color: var(--primary-3);
      box-shadow: var(--modal-shadow);
      border: none;
      transition: background-color var(--transition);

      &.notAvailable {
        background-color: rgba(235, 87, 87, 0.2);
      }
    }
  }

  &.draggablePadGhost {
    @extend .draggableGhost;

    width: 200px;
    height: 23px;

    > .card {
      width: 100%;
      height: 100%;
      box-shadow: var(--modal-shadow);
      border: none;
      transition: background-color var(--transition);

      &.notAvailable {
        background-color: rgba(235, 87, 87, 0.2);
        box-shadow: none;
      }
    }
  }
}

.draggableContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  z-index: 99;
}

.draggablePadShadow {
  opacity: 0.5;
  z-index: 100;
}

.draggableWellShadow {
  height: 100%;
  opacity: 0.5;
  z-index: 100;
}

.sortableDragging {
  z-index: 101;
}
