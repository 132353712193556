@use 'src/styles/templates';
@import 'src/styles/typography';

:root[data-theme='light'] {
  --compare-sidebar-switcher-wrapper: transparent;
  --compare-sidebar-switcher-bg: var(--primary-2);
}

:root[data-theme='dark'] {
  --compare-sidebar-switcher-wrapper: var(--primary-2);
  --compare-sidebar-switcher-bg: var(--primary-1);
}

.switcherWrapper {
  width: 100%;
  min-height: 64px;
  padding-left: 24px;
  background-color: var(--compare-sidebar-switcher-wrapper);
  display: flex;
  align-items: center;
  transition: var(--transition);
}

.overwriteSwitcherBgColor {
  background-color: var(--compare-sidebar-switcher-bg);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 8px 24px 28px 24px;
}

.icon {
  stroke: var(--contrast);
  transition: var(--transition);
}

.compareButton {
  @extend %ghost-button;

  width: 100%;
  min-height: 28px;
  margin-top: 24px;
  border-radius: 4px;
  background-color: var(--green-color);
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.7;
  }
}

.buttonText {
  @extend %body-m;

  margin-left: 8px;
}
