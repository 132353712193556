@import 'src/styles/typography';

.container {
  height: 100%;
  padding: 26px 16px 24px 24px;
  display: flex;
  flex-flow: column;
}

.title {
  @extend .body-m-bold;

  margin-bottom: 8px;
  margin-left: 8px;
  color: var(--contrast);
}

.stageCard {
  margin-bottom: 8px;
}

.returnButton {
  margin-bottom: 8px;
  padding-right: 0;
  align-self: flex-end;
}

.footer {
  margin-top: 16px;
  padding: 0 8px 4px;
  display: flex;
  flex-flow: row nowrap;
  color: var(--contrast);

  &Label {
    @extend .body-m-bold;

    width: 100%;
  }

  &Value {
    @extend .body-m;
  }
}

.arrowIcon {
  width: 20px;
  height: 20px;

  path {
    fill: currentColor;
  }
}
