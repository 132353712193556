.filter {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  height: 100%;
  background: var(--background-color);
  padding: 16px 24px;
  overflow-y: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.filterTitle {
  font-size: 15px;
  font-weight: 700;
  color: var(--contrast);
  padding-bottom: 12px;
  border-bottom: 1px solid #ffffff1a;
}

.controlsWrapper {
  margin-bottom: 24px;
}

.combobox {
  margin-top: 32px;
}

.resetButton {
  width: 100%;
}
