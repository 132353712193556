.table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  color: var(--secondary-2);

  &Wrapper {
    width: 100%;
    position: relative;
  }

  &Row {
    min-height: 30px;
    display: flex;
    flex-flow: row nowrap;
  }
}
