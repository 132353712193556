@use 'src/styles/typography';

:root[data-theme='light'] {
  --control-bar-bg-color: var(--white-color);
}

:root[data-theme='dark'] {
  --control-bar-bg-color: var(--primary-2);
}

.wrapper {
  width: 100%;
  height: 52px;
  background: var(--control-bar-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;

  .switchersBlock {
    display: flex;

    .wellsTabs {
      margin-left: 48px;
    }
  }
}
