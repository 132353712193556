.loader {
  margin: auto;

  &Container {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--backdrop-transparent);
  }
}
