:root[data-theme='light'] {
  --new-well-input-border-color: rgba(101, 98, 245, 0.7);
}

:root[data-theme='dark'] {
  --new-well-input-border-color: rgba(69, 65, 201, 0.7);
}

.textAreaWrapper {
  :global(.ant-input-affix-wrapper *) {
    transition: var(--transition);
  }

  :global(.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless)) {
    border-color: var(--red-color) !important;
  }

  :global(.ant-input-affix-wrapper-focused) {
    border-color: var(--brand-2);
    box-shadow: none;
    &:hover {
      border-color: var(--brand-2);
    }
  }

  :global(.ant-input-affix-wrapper) {
    &:focus {
      box-shadow: none;
    }
  }

  :global(.ant-input:focus) {
    box-shadow: none;
    border-color: var(--brand-2);
    &:hover {
      border-color: var(--brand-2);
    }
  }
  :global(textarea.ant-input) {
    min-height: 176px;
    max-height: 240px;
  }
}

.textArea {
  width: 100%;

  &:not(:global(.ant-input-affix-wrapper-disabled)) {
    &:hover {
      border-color: var(--new-well-input-border-color);
    }
  }

  &:disabled {
    border-color: var(--new-well-input-disabled-color);
    color: var(--new-well-disabled-inputs-text-color);

    &::placeholder {
      color: var(--new-well-disabled-inputs-text-color);
    }

    &:hover {
      border-color: var(--new-well-input-disabled-color);
    }
  }

  :global(.ant-input *) {
    transition: var(--transition);
  }

  :global(.ant-input-disabled) {
    opacity: 0.5;
    color: var(--contrast);
    transition: var(--transition);
  }
}
