@import 'src/styles/typography';
@import 'src/styles/templates';

:root[data-theme='light'] {
  --card-background-color: var(--primary-1);
  --card-border-color: #ffffff;
}

:root[data-theme='dark'] {
  --card-background-color: var(--primary-3);
  --card-border-color: var(--primary-1);
}

.card {
  width: calc(100% - 2px);
  padding: 6px 0 4px;
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--card-background-color);
  border: 1px solid var(--card-border-color);
  color: var(--contrast);

  &ColoredBorder {
    width: 100%;
    height: 4px;
    position: absolute;
    top: 0;
  }

  &Header {
    margin: 0 4px 2px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &Wrapper {
      width: 100%;
      display: flex;
      flex-flow: column;
      color: inherit;
    }

    &Title {
      @extend %text-ellipsis;

      margin-right: auto;
      margin-bottom: 0;
      line-height: 1.43;
      color: inherit;
    }

    &LoadingTitle {
      height: 16px;
      margin-bottom: 2px;
    }
  }

  &Text {
    @extend %text-ellipsis;

    margin: 0;
    color: inherit;
  }

  &Inner {
    padding: 0 4px;
    font-size: 11px;
    line-height: 145%;
    letter-spacing: 0.005em;
    color: var(--contrast-transparent);
  }
}

.loadingField {
  height: 12px;
  margin-bottom: 4px;
}
