@import 'src/styles/typography';
@import 'src/styles/templates';

.card {
  width: 100%;
  height: 100%;
  padding: 4px 0 2px;
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--primary-3);
  border-radius: 8px;
  border: 1px solid var(--primary-2);
  overflow: hidden;

  &Header {
    width: 100%;
    position: absolute;
    display: flex;

    &DeleteButton {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      cursor: pointer;
      margin-right: 8px;
      outline: none;

      &Icon {
        width: 16px;
        height: 16px;
      }
    }

    &Wrapper {
      width: 100%;
      height: 20px;
      padding: 0 2px 4px;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      color: var(--contrast);
    }

    &Title {
      @extend .subTextFont;
      @extend %text-ellipsis;

      width: 100%;
      margin: 0;
      color: inherit;
    }
  }

  &WellsWrapper {
    padding-top: 24px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
  }

  &Icon {
    min-width: 16px;
    height: 16px;
    margin-left: 2px;

    &:first-of-type {
      margin-left: 8px;
    }
  }
}

.extra {
  margin-left: 16px;
}
