:root[data-theme='light'] {
  --unplanned-rig-operations-sidebar-control-bar-bg-color: transparent;
}

:root[data-theme='dark'] {
  --unplanned-rig-operations-sidebar-control-bar-bg-color: var(--primary-2);
}

.container {
  height: 60px;
  width: 100%;
  background: var(--unplanned-rig-operations-sidebar-control-bar-bg-color);
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
