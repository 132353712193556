@import 'src/styles/typography';
@import 'src/styles/templates';

.wrapper {
  gap: 16px;
}

.wellName {
  @extend .h3;

  color: var(--contrast);
  min-width: 80px;
  width: 120px;
  transition: var(--transition);
  flex-shrink: 2;
}

.conflictsCounter {
  display: flex;
  align-items: center;
  padding: 8px;
  padding-right: 16px;
  background-color: rgba(235, 87, 87, 0.4);
  border-radius: 8px;
  transition: var(--transition);
  overflow: hidden;
}

.conflictsCounterText {
  @extend .body-m;
  @extend %text-ellipsis;

  color: var(--contrast);
  cursor: default;
}

.buttonsWrapper {

  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.atentionIcon {
  min-width: 20px;
  height: 20px;
  color: var(--red-color);
  margin-right: 8px;
}

.buttonsGroup {
  display: flex;
  align-items: center;
}

.buttonText {
  @extend %text-ellipsis;
}

.cancelButton {
  margin-right: 8px;
}


.controlButtonsGroup {
  // flex: 1 1;
  display: flex;
  gap: 16px;
  align-items: center;

  * {
    white-space: nowrap;
  }
}