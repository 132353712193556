.container {
  width: 100%;
  display: flex;

  .cell {
    width: 100%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;

    &:not(:first-child, :last-child) {
      padding: 0 8px;
    }

    &:first-child {
      padding-right: 8px;
    }

    &:last-child {
      padding-left: 8px;
    }
  }
}
