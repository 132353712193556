@use 'src/styles/typography';

.wrapper {
  display: flex;
  align-items: center;

  &_hidden {
    display: none;
  }
}

.geoTaskText {
  @extend .body-m;

  color: var(--contrast);
  margin-right: 12px;
  transition: var(--transition);
}
