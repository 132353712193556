.mainContentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  align-items: center;

  &_hidden {
    display: none;
  }
}

.container {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: scroll;

  .loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
