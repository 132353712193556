@import 'src/styles/typography';
@import 'src/styles/variables';
@import 'src/styles/templates';

[data-theme='light'] {
  --summary-row-border-color: var(--primary-2);
  --summry-header-color: rgba(6, 21, 43, 0.75);
  --summary-header-background-color: var(--white-color);
}

[data-theme='dark'] {
  --summary-row-border-color: rgba(255, 255, 255, 0.1);
  --summry-header-color: rgba(255, 255, 255, 0.6);
  --summary-header-background-color: var(--primary-1);
}

.container {
  --summary-columns-number: 0;
  --summary-columns-width: 0;

  position: relative;
  overflow: hidden;

  &_comparing {
    @extend .container;

    height: 100%;
    padding-top: 28px;
  }
}

.row {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--summary-row-border-color);
  color: var(--contrast);
  background-color: var(--summary-header-background-color);

  &Data {
    padding: 16px 24px;
  }

  &TableRow {
    @extend .caption;

    width: calc(100% - 52px);
    height: 20px;
    display: grid;
    grid-template-columns: 64px repeat(var(--summary-columns-number), var(--summary-columns-width));
    align-items: center;
    column-gap: 16px;

    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  &Loading {
    width: 100%;
    height: 100%;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  &Comparing {
    background-color: var(--comparing-background);
  }
}

.header {
  @extend .row;

  padding: 0 24px;
  position: absolute;
  top: 0;
  background-color: var(--summary-header-background-color);
  border: none;
  border-bottom: 1px solid var(--summary-row-border-color);
  z-index: 10;

  .rowTableRow {
    height: 27px;
    padding-top: 6px;
    align-items: end;
    color: var(--summry-header-color);
  }
}

.wideHeader {
  height: 36px;
}

.zeroData {
  color: var(--contrast-transparent);
}

.cell {
  display: flex;
  flex-flow: row nowrap;
  line-height: 20px;

  &Value {
    @extend %text-ellipsis;

    max-width: calc(100% - 20px);
  }

  &_positive {
    @extend .cell;

    background-color: var(--green-20-color);

    > svg.arrowIcon {
      > path {
        fill: var(--green-color);
      }
    }
  }

  &_negative {
    @extend .cell;

    background-color: var(--red-20-color);

    > svg.arrowIcon {
      transform: rotate(180deg);

      > path {
        fill: var(--red-color);
      }
    }
  }
}

svg.arrowIcon {
  width: 20px;
  height: 20px;
  margin-left: auto;
}
