@import 'src/styles/typography';
@import 'src/styles/templates';
@import 'src/styles/variables';

:root[data-theme='light'] {
  --border-color: var(--secondary-1);
}

:root[data-theme='dark'] {
  --border-color: var(--secondary-2);
}

.wrapper {
  @extend %grabbing;

  height: 100%;
  position: relative;
  overflow: hidden;
}

.rows {
  @extend .caption;

  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.row {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--border-color);
  z-index: 5;
  transition: background var(--transition), opacity var(--transition);
  background-color: var(--row-background-color);

  &Header {
    @extend .row;

    z-index: 11;
  }

  &Comparing:nth-child(n) {
    @extend .row;

    background-color: var(--comparing-background);
  }

  &Editing {
    @extend .row;

    width: 100%;
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    z-index: 13;

    .addRigButton {
      margin: 4px 8px 4px auto;
      padding: 4px 8px;
    }
  }
}

.rigRow {
  @extend .row;

  display: flex;
  flex-flow: row nowrap;
  background-color: var(--row-background-color);
  z-index: 5;
  overflow: hidden;
}

.temporaryRigRow {
  @extend .rigRow;

  animation: insertion-highlight 1s ease-in-out forwards;
}

.addWellButton {
  height: 28px;
  padding: 4px 8px;

  &Wrapper {
    position: absolute;
    top: 4px;
    right: 8px;
    z-index: 12;
  }
}

svg.addRigIcon,
svg.addWellIcon {
  path {
    stroke: var(--white-color);
  }
}

.sortableGhost {
  border-top: 1px solid var(--border-color);
  box-shadow: 4px 30px 20px rgb(0 0 0 / 5%);
  z-index: 99;
}

.ghostRigRow {
  opacity: 0.5;
}
