@use 'src/styles/typography';

.arrowIcon {
  width: 24px;
  height: 24px;
  fill: var(--contrast);
  transform: rotate(90deg);
  transition: var(--transition);

  &Up {
    transform: rotate(-90deg);
  }
}
