:root[data-theme='light'] {
  --button-cell-bg-color: var(--white-color);
}

:root[data-theme='dark'] {
  --button-cell-bg-color: var(--primary-1);
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--button-cell-bg-color);

  .button {
    border: none;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--brand-1);
  }
}
