@use 'src/styles/typography';
@use 'src/styles/templates';

.test {
  height: 0;
  position: relative;
}

.label {
  @extend .caption;
  @extend %text-ellipsis;

  max-width: 100%;
  height: min-content;
  margin-bottom: 2px;
  color: var(--contrast-transparent);
  transition: var(--transition);
  position: absolute;
  bottom: 0;
}
