@use 'src/styles/typography';

.cellContainer {
  height: 100%;
  width: 100%;

  :global {
    .ant-typography {
      font-family: Inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--contrast);
      margin: 0;
    }
  }
}
