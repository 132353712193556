@use 'src/styles/typography';

.wrapper {
  margin: 52px 16px 0 24px;
}

.panelHeader {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.panelHeaderText {
  @extend .h3;

  min-width: 120px;
}

.changesWrapper {
  padding: 4px 0;
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.changeRow {
  @extend .body-m;

  color: var(--contrast);
  opacity: 0.55;
  transition: var(--transition);
}
