.contentContainer {
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
}

.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--backdrop-transparent);
}
