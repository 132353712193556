.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .dragHandler {
    margin-right: 16px;
    cursor: move;
    color: var(--contrast);
  }

  .showHandler {
    margin-right: 16px;
  }
}
