:root[data-theme='light'] {
  --ant-progress-inner-bg-color: var(--primary-1);
}

:root[data-theme='dark'] {
  --ant-progress-inner-bg-color: var(--primary-3);
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .valueContainer {
    flex: 1;
  }

  :global {
    .ant-progress {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }

    .ant-progress-text {
      margin: 0;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.005em;
      color: inherit;
    }

    .ant-progress-line {
      font-size: 2px;
    }

    .ant-progress-show-info .ant-progress-outer {
      margin: 0;
      padding: 0;
    }

    .ant-progress-inner {
      background: var(--ant-progress-inner-bg-color);
    }
  }
}
