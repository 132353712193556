@use 'src/styles/templates';
@use 'src/styles/typography';

a.addNewValueButton {
  @extend %ghost-button;
  @extend .button-m;

  color: var(--brand-1);
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-color: transparent;
  width: max-content;
  display: flex;
  transition: var(--transition);

  &:hover {
    color: var(--brand-2);
    text-decoration-color: var(--brand-2);
  }
}
