:root[data-theme='light'] {
  --pad-collapse-bg-color: var(--primary-2);
}

:root[data-theme='dark'] {
  --pad-collapse-bg-color: rgba(26, 32, 44, 0.72);
}

.container {
  margin-bottom: 8px;

  &:last-of-type {
    margin: 0;
  }

  :global {
    .ant-collapse > .ant-collapse-item {
      border-radius: 8px;
    }

    .ant-collapse-item {
      background: var(--pad-collapse-bg-color);
      padding: 16px 4px 16px 4px;
      border: none;

      .ant-collapse-header {
        display: flex;
        align-items: center;
        padding: 0;

        .ant-collapse-expand-icon {
          display: flex;
          align-items: center;
          margin-right: 16px;
          margin-left: 4px;
        }
      }

      .ant-collapse-content-box {
        margin-top: 16px;
      }
    }

    .ant-radio-wrapper {
      color: var(--contrast);

      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
        border-color: transparent;
      }

      .ant-radio-checked {
        &::after {
          border: 1px solid var(--brand-1);
        }
      }

      .ant-radio-inner {
        border-color: var(--brand-1);

        &::after {
          background-color: var(--brand-1);
        }
      }

      .ant-radio-wrapper:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: var(--brand-1);
      }
    }
  }
}

.expandIcon {
  color: var(--contrast);
  transition: var(--transition);
  cursor: pointer;

  &Active {
    transform: rotate(180deg);
  }
}

.well {
  margin: 8px 0;

  &:first-of-type {
    margin: 0 0 8px 0;
  }

  &:last-of-type {
    margin: 8px 0 0 0;
  }

  &Icon {
    width: 16px;
    height: 16px;
  }
}
