:root[data-theme='light'] {
  --new-well-form-bg-color: white;
}

:root[data-theme='dark'] {
  --new-well-form-bg-color: var(--primary-1);
}

.wrapper {
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  border-radius: 8px;
  flex-grow: 1;
  background-color: var(--new-well-form-bg-color);
  display: flex;
  gap: 56px;
  transition: var(--transition);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  &__noBgColor {
    background-color: transparent;
    padding: 0;
  }
}

.columnsWrapper {
  width: 100%;
  height: min-content;
  display: flex;
  gap: 60px;
  overflow: auto;
}
