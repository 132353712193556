:root[data-theme='light'] {
  --matching-row-bg: var(--primary-2);
}

:root[data-theme='dark'] {
  --matching-row-bg: rgba(101, 98, 245, 0.1);
}

.matchingRow {
  width: 100%;
  display: flex;
}

.systemDataFieldWrapper {
  width: 50%;
}

.systemItem {
  padding-right: 8px;
}

.icon {
  stroke: transparent;
  fill: var(--contrast);
  transition: var(--transition);
}

.plusIcon {
  stroke: var(--contrast);
  transition: var(--transition);
}
