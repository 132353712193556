.container {
  display: flex;
  margin-left: 24px;
  padding: 0 8px;

  .button {
    cursor: pointer;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--contrast);
    margin: 0 8px;
  }
}
