.wrapper {
  width: 100%;

  :global {
    .ant-picker {
      padding: 0;
      border: none;
      display: flex;
    }

    .ant-picker-focused {
      box-shadow: none;
    }

    .ant-picker-input > input {
      color: var(--contrast);
      font-family: 'Inter';
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;
    }

    .ant-picker-suffix,
    .ant-picker-clear {
      color: var(--contrast);
    }
  }
}
