@use 'src/styles/typography';

.listItem {
  margin: 4px 0;
  padding-left: 32px;
  display: flex;
  align-items: center;

  &Text {
    @extend .body-m;
    margin: 0;
    cursor: pointer;
  }

  &Button {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border: none;
    outline: none;
    cursor: pointer;

    &Icon {
      width: 16px;
      height: 16px;
    }
  }
}
