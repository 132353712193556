@import 'src/styles/variables';

.card {
  width: 100%;
  padding: 8px 16px;
  color: var(--contrast);

  &Description {
    @include multiline-text-ellipsis(2);

    width: 100%;
    margin: 4px 0 0;
    color: var(--contrast-transparent);
  }

  &Header {
    margin: 0;
    color: var(--contrast);
  }
}
