@import 'src/styles/typography';

:root[data-theme='light'] {
  --background-color: var(--white-color);
}

:root[data-theme='dark'] {
  --background-color: #364153;
}

.container {
  width: calc(100% - 341px);
  height: 100%;
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  justify-content: space-between;
}

.chartsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  background: var(--background-color);
  overflow: auto;
  padding: 16px;
  border-radius: 8px;
}

.timelineWrapper {
  min-height: 100px;
  width: 100%;
}

.timeline {
  min-height: 50px;
  height: 50px;
  display: grid;
  grid-template-areas: 'periodButtons timeline';
  grid-template-columns: 255px auto;
  align-items: center;
  background-color: var(--timeline-background-color);

  &Wrapper {
    grid-area: timeline;
  }

  &PeriodsToggle {
    grid-area: periodButtons;
    width: 100%;
  }
}

.timelinePeriodButton {
  @extend .caption;

  padding: 6px 7px;
  flex-grow: 1;
}
