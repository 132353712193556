@use 'src/styles/typography';

.wrapper {
  display: flex;
  align-self: flex-end;
  padding-bottom: 3px;
}

.navWrapper {
  height: 40px;
  padding: 0 8px;
  transition: var(--transition);
  border-bottom: 2px solid var(--brand-1);

  &__inactive {
    border-bottom: 2px solid transparent;

    &:focus-visible,
    &:hover {
      border-bottom: 2px solid var(--brand-3);
    }
  }
}

.navTitle {
  color: var(--brand-1);
  opacity: 1;
  margin-bottom: 0;
  transition: var(--transition);

  &__inactive {
    color: var(--contrast-transparent);
  }
}
