@import 'src/styles/typography';
@import 'src/styles/templates';

[data-theme='light'] {
  --well-card-background-color-light: var(--white-color);
}

[data-theme='dark'] {
  --well-card-background-color-light: var(--primary-2);
}

.gray {
  background-color: var(--primary-1);
}

.white {
  background-color: var(--well-card-background-color-light);
}

.titleColumn {
  @extend .body-m;

  $columnWidth: calc((100% - (16px + 4px)) / 2);

  display: inline-block;

  &:nth-child(odd) {
    width: $columnWidth;
    margin-right: 16px;
  }

  &:nth-child(even) {
    width: $columnWidth;
    margin-right: 4px;
  }
}

svg.wellTypeIcon {
  width: 16px;
  height: 16px;
  opacity: 0.3;

  path {
    stroke: var(--contrast);
  }
}

.moveElement {
  @extend %grabbing;

  width: 20px;
  height: 20px;
  margin-right: 12px;
  display: flex;

  svg.moveIcon {
    width: 16px;
    height: 16px;
    margin: auto auto auto 0;

    path {
      fill: var(--contrast);
    }
  }
}

.well {
  &Dragging {
    width: 120px;
    height: 20px;
    position: absolute;
    background-color: var(--primary-2);
    z-index: 100;
  }
}
