.tooltip {
  &Icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  &Row {
    margin: 2px 0;
    display: flex;
    gap: 8px;
    flex-flow: row;
    align-items: center;
  }
}

.regularAttrLabel {
  color: var(--contrast);
  opacity: 0.7;
}

.regularAttrValue,
.titleAttrValue {
  color: var(--contrast);
}
