:root[data-theme='light'] {
  --header-background-color: var(--white-color);
  --switcher-background-color: var(--primary-2);
}

:root[data-theme='dark'] {
  --header-background-color: var(--primary-2);
  --switcher-background-color: #364153;
}

.container {
  height: 52px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  background-color: var(--header-background-color);
}

.switcher {
  background-color: var(--switcher-background-color);

  &Active {
    background-color: var(--brand-1);
  }
}

.switch {
  &Container {
    height: 36px;

    & > .switchButton,
    button {
      height: 36px;
    }
  }
}

.controlsWrapper {
  display: flex;
  column-gap: 24px;
}

.accumulateSwitcher {
  margin-left: 24px;
  display: flex;
  align-items: center;
  column-gap: 24px;
  color: var(--contrast);
}

.controls {
  &Container {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
  }

  &Button {
    height: 36px;
    padding-left: 8px;
    padding-right: 8px;

    &Icon {
      path {
        fill: var(--contrast);
      }
    }

    &Edit &Icon {
      path {
        fill: none;
        stroke: var(--contrast);
      }
    }
  }
}

.editingControls {
  display: flex;
  flex-flow: row nowrap;

  &Button {
    height: 28px;
    margin: auto 16px auto 0;
    padding: 4px 16px;
  }
}

svg.switchIcon {
  > path {
    stroke: currentColor;
  }
}
