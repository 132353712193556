:root[data-theme='light'] {
  --free-well-background-color: var(--primary-2);
}

:root[data-theme='dark'] {
  --free-well-background-color: rgba(26, 32, 44, 0.72);
}

.wrapper {
  padding: 16px 8px 0 24px;
}

.container {
  height: 100%;
  margin-bottom: 16px;
}

.addButtonContainer {
  margin-bottom: 16px;
}

.hint {
  color: var(--contrast);
  opacity: 0.5;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.freeWell {
  background-color: var(--free-well-background-color);
}
