@use 'src/styles/typography';

:root[data-theme='light'] {
  --group-container-border-color: var(--primary-3);
}

:root[data-theme='dark'] {
  --group-container-border-color: rgba(255, 255, 255, 0.1);
}

.collapse {
  margin: 12px 0;

  :global {
    .ant-collapse-item {
      .ant-collapse-header {
        display: flex;
        align-items: center;
        padding: 0;

        .ant-collapse-header-text {
          order: 1;
        }

        .ant-collapse-expand-icon {
          order: 2;
          display: flex;
          align-items: center;
          margin: 0 16px 0 0;
        }

        .ant-collapse-extra {
          order: 3;
          margin: 0;
          flex: 1;
        }
      }
    }
  }
}

.settingGroup {
  border-top: 1px solid var(--group-container-border-color);
  border-bottom: 1px solid var(--group-container-border-color);
  padding: 8px 0;
  margin-top: 8px;
}
