:root[data-theme='light'] {
  --drilling-carpet-menu-bar-bg: white;
}

:root[data-theme='dark'] {
  --drilling-carpet-menu-bar-bg: var(--primary-2);
}

.wrapper {
  width: 100%;
  min-height: 52px;
  padding: 4px 16px;
  background-color: var(--drilling-carpet-menu-bar-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: var(--transition);
}
