:root[data-theme='light'] {
  --row-background-color: #ffffff;
  --collapse-header-border-color: var(--secondary-1);
}

:root[data-theme='dark'] {
  --row-background-color: var(--primary-1);
  --collapse-header-border-color: var(--secondary-2);
}

.collapseHeader {
  width: 100%;
  height: 100%;
  padding: 4px 8px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: none;
  text-align: left;
  color: var(--contrast);
  border-bottom: 1px solid var(--collapse-header-border-color);
  background-color: var(--row-background-color);
  cursor: pointer;
  overflow: hidden;

  &Wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    border-top-left-radius: var(--table-border-radius);
    overflow: hidden;
    z-index: 7;
    outline: none;
    border: none;
  }

  &CollapsedWrapper {
    border-bottom-left-radius: var(--table-border-radius);
  }
}

.arrowIcon {
  width: 24px;
  height: 24px;
  margin-right: 1.33px;
  transform: rotate(90deg);
  transition: transform var(--transition);
  fill: var(--contrast);

  &Up {
    transform: rotate(-90deg);
  }
}

.sortable:nth-child(n) {
  opacity: 0.5;
}

.sortableGhost {
  border-top: 1px solid var(--collapse-header-border-color);
  box-shadow: 4px 30px 20px rgb(0 0 0 / 5%);
  z-index: 99;
}
