@import 'src/styles/typography';
@import 'src/styles/templates';

.wrapper {
  height: 100%;
  padding: 4px 24px 16px;
  display: flex;
  flex-flow: column;
}

.text {
  @extend .body-m;

  margin-bottom: 16px;
  color: var(--contrast);
  opacity: 0.6;
}

.applyButton {
  margin-top: auto;
}
