:root[data-theme='light'] {
  --new-well-regular-select-border: var(--primary-3);
  --new-well-regular-select-hover-border-color: rgba(101, 98, 245, 0.7);
  --new-well-regular-select-bg: var(--white-color);
}

:root[data-theme='dark'] {
  --new-well-regular-select-border: var(--primary-2);
  --new-well-regular-select-hover-border-color: rgba(69, 65, 201, 0.7);
  --new-well-regular-select-bg: var(--primary-2);
}

.select {
  color: var(--contrast);
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--new-well-regular-select-border);
  background-color: var(--new-well-regular-select-bg);
  transition: var(--transition);

  &__error {
    border-color: var(--red-color);
  }

  &:hover {
    border-color: var(--new-well-regular-select-hover-border-color);
  }

  &:focus-within {
    border: 1px solid var(--brand-2);
  }
}

.loaderWrapper {
  margin-top: 4px;
}

.icon {
  transition: var(--transition);
  transform: rotate(90deg);
}
