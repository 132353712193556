@import 'src/styles/typography';
@import 'src/styles/templates';
@import 'src/styles/variables';

.card {
  width: 100%;
  padding: 6px 0;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &ColoredBorder {
    width: 100%;
    height: 4px;
    position: absolute;
    top: 0;
  }

  &Header {
    margin: 0 4px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: var(--contrast);

    &DeleteButton {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      cursor: pointer;
      margin-right: 8px;
      outline: none;

      &Icon {
        width: 16px;
        height: 16px;
      }
    }

    &Wrapper {
      width: 100%;
      display: flex;
      flex-flow: column;
    }

    &Title {
      @extend %text-ellipsis;

      margin-right: auto;
      margin-bottom: 0;
      color: inherit;
    }

    &LoadingTitle {
      height: 16px;
      margin-bottom: 0;
    }
  }

  &Text {
    @extend %text-ellipsis;

    margin: 0;
  }

  &Inner {
    padding: 0 4px;
    font-size: 11px;
    line-height: 145%;
    letter-spacing: 0.005em;
    color: var(--contrast-transparent);
  }
}

.loadingField {
  height: 12px;
  margin-bottom: 4px;
  animation: skeleton-loading 1s linear infinite alternate;
}
