@use 'src/styles/typography';

.modal {
  width: 456px;
}

.description {
  @extend %body-m;
  color: var(--contrast);
}

.select {
  margin-top: 24px;

  &Label {
    @extend %caption;
    color: var(--contrast);
    opacity: 0.5;
  }
}

.buttonsGroup {
  margin: 48px -10px 0px -10px;
  display: flex;

  .button {
    flex: 1 1 auto;
    margin: 0 10px;
    width: 100%;
  }

  .iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: nine;
    background: transparent;
    cursor: pointer;

    .icon {
      color: var(--contrast);
    }
  }
}
