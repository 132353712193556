.button {
  width: 28px;
  height: 32px;
  margin: auto 0 2px;
  display: flex;
  border-radius: 4px;
  background-color: var(--background-2);
  border: none;
  outline: none;
  cursor: pointer;
}

svg.arrowIcon {
  width: 24px;
  height: 24px;
  margin: auto;
  transform: rotate(-90deg);
  transition: transform var(--transition);

  > path {
    fill: var(--contrast);
  }

  &Up {
    transform: rotate(90deg);
  }
}
