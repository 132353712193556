@use 'src/styles/typography';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  @extend %h3;

  color: var(--contrast);
  transition: var(--transition);
}

.buttonsWrapper {
  display: flex;
  gap: 16px;
}
