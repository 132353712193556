@use 'src/styles/typography';

:root[data-theme='light'] {
  --mail-img_sheet-row: rgba(26, 32, 44, 0.4);
}

:root[data-theme='dark'] {
  --mail-img_sheet-row: rgba(26, 32, 44, 1);
}

.modal {
  width: 456px;
}

.title {
  @extend .h2;

  color: var(--contrast);
  transition: var(--transition);
}

.text {
  @extend .body-l;

  color: var(--contrast);
  padding: 24px 0;
  transition: var(--transition);
}

.dataListItem {
  @extend .body-l;

  color: var(--contrast);
  transition: var(--transition);
}

.buttonsWrapper {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mailImg {
  :global {
    .mail-img_back-figure {
      fill: var(--primary-2);
    }
    .mail-img_sheet-row {
      fill: var(--mail-img_sheet-row);
    }
  }
}
