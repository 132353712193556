@use 'src/styles/typography';

:root[data-theme='light'] {
  --search-border-color: var(--primary-3);
  --search-placeholder-color: rgba(6, 21, 43, 0.5);
}

:root[data-theme='dark'] {
  --search-border-color: transparent;
  --search-placeholder-color: var(--secondary-1);
}

.container {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 6px;
  background: var(--background-1);
  border: 1px solid var(--search-border-color);
  border-radius: 4px;

  .searchIcon {
    color: var(--contrast);
    transition: var(--transition);
  }

  .searchInput {
    @extend %body-m;

    width: 100%;
    margin-left: 8px;
    background: transparent;
    border: none;
    outline: none;
    color: var(--contrast);

    &::placeholder {
      @extend %body-m;
      color: var(--search-placeholder-color);
    }
  }
}
