@use 'src/styles/typography';

:root[data-theme='light'] {
  --table-bg-color: var(--white-color);
  --table-cell-border-color: var(--secondary-1);
  --comparing-cell-bg-color: rgba(58, 54, 219, 0.2);
}

:root[data-theme='dark'] {
  --table-bg-color: var(--primary-1);
  --table-cell-border-color: var(--secondary-2);
  --comparing-cell-bg-color: rgba(96, 92, 255, 0.4);
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  padding: 16px 0 0 16px;
}

.tableBodyContainer {
  display: flex;

  .fixedColumn {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: inherit;

    &Last {
      border-right: 2px solid var(--table-cell-border-color);
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

.virtualTableGrid {
  &::-webkit-scrollbar {
    width: 12px;
  }
}

.fixedTableCell {
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  color: var(--contrast);
  background: transparent;
  border-bottom: 1px solid var(--table-cell-border-color);

  &Comparing {
    background: var(--comparing-cell-bg-color);
  }
}

.tableContainer {
  :global {
    .ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before {
      box-shadow: none;
    }

    .ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after {
      box-shadow: none;
    }

    .ant-table-thead {
      tr {
        th {
          border-bottom: 1px solid var(--table-cell-border-color);
          padding: 8px;
          background: transparent;
          font-weight: 700;
          font-size: 15px;
          color: var(--contrast);
          vertical-align: top;

          &[colspan]:not([colspan='1']) {
            text-align: left;
          }

          &::before {
            top: 16px !important;
            background-color: var(--table-cell-border-color) !important;
          }

          &:hover {
            background: transparent;

            &::before {
              background-color: var(--table-cell-border-color) !important;
            }
          }
        }

        &:nth-child(2) {
          th {
            padding: 0 8px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);

            &::before {
              display: none;
            }
          }
        }
      }
    }

    .virtualTableCell {
      border-bottom: 1px solid var(--table-cell-border-color);
      background: transparent;
      font-weight: 400;
      font-size: 14px;
      color: var(--contrast);

      &Comparing {
        background: var(--comparing-cell-bg-color);
      }
    }

    .ParentColumn {
      overflow: visible;
      border-bottom: none !important;

      &::after {
        position: absolute;
        top: 6px;
        right: 0;
        width: 1px;
        height: 1.6em;
        background-color: var(--table-cell-border-color);
        transform: translateY(-10%);
        content: '';
      }
    }

    .ant-table {
      background: transparent;

      &:hover {
        background: transparent;
      }
    }

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background: var(--table-bg-color) !important;
    }

    .ant-table-cell-fix-left-last {
      border-right: 2px solid var(--table-cell-border-color);

      &::before {
        display: none;
      }

      &::after {
        box-shadow: none;
      }
    }

    .ant-table-column-sorter {
      display: flex;
    }

    .ant-table-cell-scrollbar {
      display: none;
    }
  }

  &Compare {
    :global {
      .virtualTableCell {
        padding: 0;
      }
    }
  }

  &Scroll {
    :global {
      .ant-table-cell-scrollbar {
        display: block;
        border: none !important;
        box-shadow: none;
      }
    }
  }
}
