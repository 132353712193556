:root[data-theme='light'] {
  --header-background-color: #ffffff;
}

:root[data-theme='dark'] {
  --header-background-color: var(--primary-2);
}

.container {
  height: 52px;
  padding: 8px 16px;
  display: flex;
  flex-flow: row nowrap;
  background-color: var(--header-background-color);
}

.switch {
  &Container {
    height: 36px;

    & > .switchButton {
      height: 100%;
    }
  }
}

.controls {
  &Container {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
  }

  &Button {
    height: 36px;
    padding-left: 8px;
    padding-right: 8px;

    &Icon {
      path {
        fill: var(--contrast);
      }
    }

    &ComparingExit {
      height: 28px;
      margin: auto 24px auto auto;
    }
  }
}

.importButton {
  height: 28px;
  margin: auto 8px;
}
