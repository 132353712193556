@import 'src/styles/typography';
@import 'src/styles/templates';

:root[data-theme='light'] {
  --border-color: var(--secondary-1);
  --table-background-color: #ffffff;
}

:root[data-theme='dark'] {
  --border-color: var(--secondary-2);
  --table-background-color: var(--primary-1);
}

.table {
  display: grid;
  grid-template-areas:
    'rowHeaders data'
    'footer footer';
  grid-template-columns: 264px auto;
  grid-template-rows: min-content 50px;
  background-color: var(--table-background-color);
  border-radius: var(--table-border-radius);

  &Collapsed {
    display: grid;
    grid-template-rows: min-content 24px;

    .dataColumn {
      padding: 0 4px;
      display: flex;
    }

    .dataColumnInner {
      margin: auto;

      &:first-of-type {
        width: 100%;
        margin-top: auto;
      }
    }
  }
}

.data {
  height: 100%;
  position: absolute;
  top: 0;
  left: 264px;
  bottom: 0;
  right: 0;
  overflow: hidden;

  &::before {
    content: '';
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: var(--border-color);
    z-index: 10;
  }

  &Container {
    @extend %grabbing;

    grid-area: data;
    width: 100%;
    height: 100%;
  }

  &Column {
    @extend .caption;

    width: 100%;
    color: var(--contrast);

    &Inner {
      height: 20px;
      margin: 16px 4px;
      display: flex;
      text-align: center;

      &:first-of-type {
        margin-top: 8px;
      }
    }

    &Empty:nth-child(n) {
      @extend .dataColumnInner;

      width: calc(100% - 4px * 2);
      border-radius: 4px;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }

  &Title {
    @extend %text-ellipsis;

    width: 100%;
    margin: auto;
  }
}

.buttonsContainer {
  grid-area: footer;
  margin-top: 0;
  position: relative;
  bottom: 4px;
  display: flex;
  flex-flow: row;
  z-index: 12;

  &Expanded {
    margin-top: 24px;
    bottom: 8px;
  }

  & > .button {
    @extend .caption;

    color: var(--contrast);

    &:not(:last-child) {
      margin-right: 46px;
    }
  }
}

.buttonIcon {
  width: 20px;
  height: 20px;
}

.collapseIcon {
  @extend .buttonIcon;

  transition: transform var(--transition);

  &Down {
    transform: rotate(90deg);
  }
}

.rowHeader {
  width: 100%;
  height: 36px;
  padding: 8px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &Wrapper {
    grid-area: rowHeaders;
    width: 264px;
    position: relative;
    display: flex;
    flex-flow: column;
  }

  &Title,
  &Total {
    @extend .caption;

    margin-bottom: 0;
    color: var(--contrast);
  }

  &Title {
    @extend %text-ellipsis;

    margin-right: auto;
    display: inline-block;
  }

  &Total {
    color: var(--contrast-transparent);
    max-width: 52px;
    width: 100%;
    margin-left: 8px;
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;

    &Label {
      @extend %text-ellipsis;
    }

    &Loading {
      width: 100%;
    }
  }
}

.dataColumnWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;

  &::after {
    content: '';
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: var(--header-grid-line-color);
    mix-blend-mode: var(--lines-blend-mode);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--empty-pad-loading-background-color-start);
  }
  100% {
    background-color: var(--empty-pad-loading-background-color-end);
  }
}
