@use 'src/styles/typography';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleContainer {
    width: min-content;
    margin-right: 16px;
    display: flex;
    align-items: center;
    flex: 1;

    .title {
      @extend %body-m-bold;

      color: var(--contrast);
    }

    .attributes {
      display: flex;
      align-items: center;

      .attributeIcon {
        width: 12px;
        height: 12px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }

  .expandButton {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .expandIcon {
      color: var(--contrast);
    }
  }
}
