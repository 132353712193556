@use 'src/styles/typography';

:root[data-theme='light'] {
  --tooltip-search-bg-color: var(--white-color);
  --tooltip-search-placeholder-color: rgba(6, 21, 43, 0.5);
}

:root[data-theme='dark'] {
  --tooltip-search-bg-color: var(--primary-3);
  --tooltip-search-placeholder-color: var(--secondary-1);
}

.topButtonsContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 340px;

  .iconButton {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 28px;
    cursor: pointer;
    color: var(--contrast);
    border-radius: 4px;

    .icon {
      width: 20px;
      height: 20px;
    }

    &Active {
      background-color: var(--brand-1);
      color: var(--white-color);
    }
  }

  .resetButton {
    margin-left: 40px;
  }
}

.search {
  @extend%body-m;
  width: 100%;
  height: 32px;
  background: var(--tooltip-search-bg-color);
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &::placeholder {
    color: var(--tooltip-search-placeholder-color);
  }
}

.checkItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &Text {
    @extend%body-m;
    margin-left: 16px;
    color: var(--contrast);
  }
}

.bottomButtonsContainer {
  display: flex;
  align-items: center;
  padding: 8px 0;

  .button {
    &:first-child {
      margin-right: 8px;
    }
  }
}
