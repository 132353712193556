@import 'src/styles/typography';
@import 'src/styles/templates';

:root[data-theme='light'] {
  --collapse-content-border-color: var(--primary-3);
  --chart-settings-tab-background-color: var(--white-color);
}

:root[data-theme='dark'] {
  --collapse-content-border-color: rgba(255, 255, 255, 0.1);
  --chart-settings-tab-background-color: var(--primary-2);
}

.container {
  height: 100%;
  padding: 4px 0 16px;
  display: flex;
  flex-flow: column;
  overflow-y: hidden;
}

.text {
  @extend .body-m;

  margin: 0 24px 16px;
  color: var(--contrast);
  opacity: 0.6;
}

.tabs {
  width: max-content;

  & > &Button {
    background-color: var(--chart-settings-tab-background-color);
  }
}

.tabContent {
  width: 100%;
  height: 100%;
  margin: 8px 0 16px 24px;
  position: relative;
  overflow-y: auto;
}

.applyButton {
  margin: auto 24px 0;
}

.loader {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.switchButtonsContainer {
  max-width: 100%;
  padding: 8px 24px 0;
}

.switchButtonsInnerContainer {
  max-width: 100%;
  padding-bottom: 4px;
  height: fit-content;
  overflow-x: auto;
}

.switchButtonGroupRefWrapper {
  width: max-content;
}
