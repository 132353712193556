:root[data-theme='light'] {
  --drilling-step-card-background-color: var(--primary-1);
  --drilling-step-card-background-dark-color: var(--primary-2);
  --drilling-step-card-border-color: #ffffff;
}

:root[data-theme='dark'] {
  --drilling-step-card-background-color: var(--primary-3);
  --drilling-step-card-background-dark-color: var(--primary-3);
  --drilling-step-card-border-color: var(--primary-1);
}

.dataItem {
  position: absolute;
  z-index: 7;
}

.card {
  height: 100%;
  background-color: var(--drilling-step-card-background-dark-color);
  border-top: 1px solid var(--drilling-step-card-border-color);
  border-bottom: 1px solid var(--drilling-step-card-border-color);
}
