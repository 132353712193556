@import 'src/styles/typography';

:root[data-theme='light'] {
  --new-well-form-date-picker-border-color: var(--primary-3);
  --new-well-datepicker-border-color: rgba(101, 98, 245, 0.7);
}

:root[data-theme='dark'] {
  --new-well-form-date-picker-border-color: var(--primary-2);
  --new-well-datepicker-border-color: rgba(69, 65, 201, 0.7);
}

.inputWrapper {
  :global(.ant-picker) {
    height: 32px;
    padding: 4px 4px 6px 16px;
  }
  :global(.ant-picker-disabled .ant-picker-suffix) {
    opacity: 0;
  }
  :global(.ant-picker-clear) {
    svg > path {
      fill: var(--contrast);
      opacity: 0.5;
    }
  }
}

.input {
  width: 100%;
  border-radius: 4px;
  background-color: var(--background-1) !important;
  border-width: 1px;
  border-style: solid;
  border-color: var(--new-well-form-date-picker-border-color) !important;
  transition: var(--transition);

  &:not(:global(.ant-picker-disabled)) {
    &:hover {
      border-color: var(--new-well-datepicker-border-color) !important;
    }
  }

  &__clearable {
    :global(.ant-picker-suffix) {
      opacity: 1;
      transition: var(--transition);
    }

    &:hover {
      :global(.ant-picker-suffix) {
        opacity: 0;
      }
    }
  }

  :global(.ant-picker-input input::placeholder),
  :global(.ant-picker-input-placeholder input) {
    color: var(--contrast);
    opacity: 0.5;
    transition: var(--transition);
  }

  :global(.ant-picker-clear) {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit;
  }

  :global(.ant-picker-input > input) {
    @extend .body-m;

    color: var(--contrast);
    transition: var(--transition);

    &:disabled {
      opacity: 0.3;
    }
  }
}

.icon {
  stroke: var(--contrast);
  transition: var(--transition);
}

.label {
  margin-top: 0;
}
