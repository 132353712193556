.cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &Button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    outline: none;
    color: var(--brand-1);
    cursor: pointer;
  }
}
