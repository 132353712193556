:root[data-theme='light'] {
  --header-background-color: #ffffff;
}

:root[data-theme='dark'] {
  --header-background-color: var(--primary-2);
}

.container {
  height: 52px;
  padding: 8px 16px;
  display: flex;
  flex-flow: row nowrap;
  background-color: var(--header-background-color);
}

.switch {
  &Container {
    height: 36px;

    & > .switchButton {
      height: 100%;

      [disabled] {
        height: 100%;
      }
    }
  }
}

.controls {
  &Container {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
  }

  &Button {
    height: 36px;
    padding: 6px 8px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    &:disabled {
      background: none;
      cursor: not-allowed;
      opacity: 0.5;
    }

    &Icon {
      path {
        fill: var(--contrast);
      }
    }

    &Edit &Icon {
      path {
        fill: none;
        stroke: var(--contrast);
      }
    }
  }
}

.editingControls {
  display: flex;
  flex-flow: row nowrap;

  &Button {
    height: 28px;
    margin: auto 16px auto 0;
    padding: 4px 16px;
  }
}
