@import 'palette';
@import 'fonts';
@import 'typography';
@import 'variables';
@import 'templates';

:root[data-theme='light'] {
  --ant-select-dropdown-bg-color: var(--primary-1);
  --ant-select-item-option-selected-bg-color: var(--white-color);
  --ant-tooltip-bg-color: var(--primary-1);
  --input-bg-color: white;
  --input-border-color: var(--primary-2);
}

:root[data-theme='dark'] {
  --ant-select-dropdown-bg-color: var(--primary-2);
  --ant-select-item-option-selected-bg-color: rgba(96, 92, 255, 0.4);
  --ant-tooltip-bg-color: var(--primary-2);
  --input-bg-color: var(--primary-2);
  --input-border-color: transparent;
}

.ant-message {
  &-notice-content,
  .anticon {
    color: var(--white-color);
  }
}

.errorMessage {
  .ant-message-notice-content {
    background: var(--red-color);
  }
}

.warningMessage {
  .ant-message-notice-content {
    background: var(--yellow-color);
  }
}

.successMessage {
  .ant-message-notice-content {
    background: var(--green-color);
  }
}

.ant-switch {
  background: var(--primary-3);
}

.ant-switch-checked {
  background: var(--brand-1);
}

.ant-switch-inner {
  display: flex;
}

.ant-tooltip-inner {
  color: var(--contrast);
  border-radius: 8px;
  background-color: var(--ant-tooltip-bg-color);
}

.ant-tooltip-arrow-content::before {
  background: var(--ant-tooltip-bg-color);
}

.ant-progress-inner {
  transition: var(--transition);
}

%input {
  color: var(--contrast);
  background-color: var(--input-bg-color) !important;
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color) !important;
  border-radius: 4px;
  transition: var(--transition);
}

.ant-input {
  color: var(--contrast);
  background-color: var(--input-bg-color) !important;
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  border-radius: 4px;
  transition: var(--transition);
}

.ant-input-affix-wrapper {
  color: var(--contrast);
  background-color: var(--input-bg-color) !important;
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  border-radius: 4px;
  transition: var(--transition);
}

.ant-input-number {
  color: var(--contrast);
  background-color: var(--input-bg-color) !important;
  border-width: 1px;
  border-style: solid;
  border-color: var(--input-border-color);
  border-radius: 4px;
  transition: var(--transition);
}

.ant-input-suffix {
  flex-direction: row-reverse;
}

.ant-input-clear-icon {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-input-clear-icon-has-suffix {
  margin-right: 0 !important;
  margin-left: 4px;
}

.ant-select-selector {
  border-radius: 4px !important;
}

.ant-select-selection-item {
  color: var(--contrast);
  transition: var(--transition);
}

.ant-select-dropdown {
  background: var(--ant-select-dropdown-bg-color);
  box-shadow: none;

  .ant-select-item-option-selected {
    background: var(--ant-select-item-option-selected-bg-color);
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: transparent;
  }

  .ant-select-item {
    &:hover {
      background: var(--ant-select-item-option-selected-bg-color);
    }
  }

  .ant-select-item {
    color: var(--contrast);
  }
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: var(--contrast);
  opacity: 0.3;
}

.ant-select-clear {
  top: 45%;
  right: 17px;
  background-color: inherit;
}

.ant-select-selection-placeholder,
.ant-input::placeholder {
  color: var(--contrast);
  opacity: 0.5;
}

.ant-picker-dropdown {
  z-index: 1071;
}

.ant-switch:focus,
.ant-switch-checked:focus {
  box-shadow: none;
}

.ant-picker-month-btn,
.ant-picker-content th {
  text-transform: capitalize;
}

.ant-picker-panel-container {
  background-color: var(--primary-1);
}

.ant-picker-header button {
  color: var(--contrast);
  transition: var(--transition);

  &:hover {
    color: var(--brand-1);
  }
}

.ant-picker-header,
.ant-picker-body,
.ant-picker-datetime-panel .ant-picker-time-panel,
.ant-picker-panel .ant-picker-footer,
.ant-picker-time-panel-column:not(:first-child),
.ant-picker-panel-container .ant-picker-panel {
  border: none;
}

.ant-picker-cell,
.ant-picker-content th,
.ant-picker-header-view,
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: var(--contrast);

  transition: var(--transition);
}

.ant-picker-cell {
  &::before {
    display: none;
  }
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background-color: transparent;
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-disabled):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background-color: var(--primary-2);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--brand-2);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: var(--brand-2);
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  &:hover {
    background-color: var(--primary-2);
  }
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: var(--brand-2);
  color: white;
}

.ant-picker-cell-disabled {
  color: var(--contrast-transparent);
  pointer-events: all;
  cursor: not-allowed;

  &::before {
    background-color: transparent;
  }
}

.ant-picker-now-btn {
  color: var(--brand-1);
  font-weight: 600;
  transition: var(--transition);

  &:hover {
    color: var(--brand-2);
  }
}

.ant-picker-ok .ant-btn {
  color: var(--brand-1);
  font-weight: 600;
  border: 1px solid var(--brand-1);
  background-color: transparent;
  transition: var(--transition);

  &:not(:disabled) {
    &:hover {
      color: white;
      border-color: var(--brand-2);
      background-color: var(--brand-2);
    }
  }

  &:disabled {
    color: var(--contrast-transparent);
    font-weight: 400;
    border: none;
    &:hover {
      background-color: transparent;
    }
  }
}

.ant-spin-dot-item {
  background-color: var(--brand-1);
}

.ant-select-tree {
  .ant-select-tree-treenode {
    padding: 0;
  }

  .ant-select-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select-tree-node-content-wrapper {
    min-height: 32px;
    display: flex;
    align-items: center;

    &:hover,
    &.ant-select-tree-node-selected {
      background: var(--ant-select-item-option-selected-bg-color);
    }
  }
}
