.tooltipButton {
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 4px;
  padding: 4px 16px;

  &Center {
    justify-content: center;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
