@import 'src/styles/templates';

.wrapper {
  height: 100%;
  margin-left: 4px;
  position: relative;
  overflow: hidden;
  border-top: 2px solid var(--brand-1);
}

.inner {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-x: scroll;
  overflow-y: hidden;
  @extend %grabbing;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.dataContainer {
  min-width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
}
