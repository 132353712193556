@use 'src/styles/typography';

:root[data-theme='light'] {
  --plan-control-bar-bg-color: var(--white-color);
  --plan-control-bar-search-bg-color: var(--white-color);
  --plan-control-bar-search-border-color: var(--primary-3);
  --plan-control-bar-search-placeholder-color: rgba(6, 21, 43, 0.5);
}

:root[data-theme='dark'] {
  --plan-control-bar-bg-color: var(--primary-2);
  --plan-control-bar-search-bg-color: var(--primary-1);
  --plan-control-bar-search-border-color: transparent;
  --plan-control-bar-search-placeholder-color: var(--secondary-1);
}

.wrapper {
  width: 100%;
  height: 52px;
  background: var(--plan-control-bar-bg-color);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
}

.rightControlBlock {
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.settingsButton {
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--contrast);
}
