.labelButton {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: var(--white-color);
  cursor: pointer;
}

.labelIcon {
  display: grid;
  place-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 4px;
}

.labelText {
  color: var(--contrast-transparent);
  white-space: nowrap;
}
