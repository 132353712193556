@use 'src/styles/typography';

.list {
  max-height: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.itemsWrapper {
  display: flex;
  flex-direction: column;
}

.text {
  @extend %body-m-bold;

  text-transform: capitalize;
}

.compareText {
  @extend %body-m;

  margin-top: 8px;
  margin-bottom: 12px;
  color: var(--contrast);
  opacity: 0.75;
  transition: var(--transition);
}

.noItemsText {
  text-align: center;
  color: var(--contrast);
  margin-top: 24px;
  opacity: 0.5;
}
