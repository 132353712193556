@import 'src/styles/typography';
@import 'src/styles/variables';

:root[data-theme='light'] {
  --border-color: var(--secondary-1);
  --table-background-color: #ffffff;
}

:root[data-theme='dark'] {
  --border-color: var(--secondary-2);
  --table-background-color: var(--primary-1);
}

.wrapper {
  position: relative;
  overflow: hidden;
  border-top-left-radius: var(--table-border-radius);
}

.headers {
  @extend .caption;

  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.rowHeader {
  width: 100%;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--table-background-color);
  z-index: 5;
  transition: background var(--transition), opacity var(--transition);
  user-select: none;
  overflow: hidden;

  &Last {
    border-bottom-left-radius: var(--table-border-radius);
  }

  &Comparing {
    background-color: var(--comparing-background);
  }

  &Interactive {
    cursor: pointer;
  }
}

.wellCard {
  &Editing:nth-child(n) {
    padding-left: 0;
  }
}

.wellIcon,
.rigIcon {
  width: 24px;
  height: 24px;

  path {
    stroke: var(--contrast);
  }
}

.description {
  &Container {
    @include multiline-text-ellipsis(2);

    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  &Comma {
    margin-right: 4px;
  }
}

.deleteButton.deleteButton {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 7px 4px 0 16px;
}

.temporaryRigHeader {
  animation: insertion-highlight 1s ease-in-out forwards;
}

.sortable:nth-child(n) {
  opacity: 0.5;
}

.sortableGhost:nth-child(n) {
  border-top: 1px solid var(--border-color);
  box-shadow: 4px 30px 20px rgb(0 0 0 / 5%);
  z-index: 99;
}
