@use 'src/styles/typography';

.container {
  @extend %body-m;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    margin: 0;
    color: var(--contrast);
  }

  .rigOperation {
    color: var(--contrast);
    margin-left: auto;
    margin-right: 140px;
  }

  :global {
    .ant-radio-wrapper {
      color: var(--contrast);

      .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: none;
        border-color: transparent;
      }

      .ant-radio-inner {
        border-color: var(--brand-1);

        &::after {
          background-color: var(--brand-1);
        }
      }

      .ant-radio-checked {
        .ant-radio-inner {
          background: var(--brand-1);

          &::after {
            background-color: var(--white-color);
          }
        }
      }

      .ant-radio-wrapper:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner {
        border-color: var(--brand-1);
      }
    }
  }
}
