@use 'src/styles/templates';
@use 'src/styles/typography';

:root[data-theme='light'] {
  --header-compare-button-bg-color: white;
  --header-compare-button-border-color: var(--primary-3);
}

:root[data-theme='dark'] {
  --header-compare-button-bg-color: var(--primary-1);
  --header-compare-button-border-color: var(--primary-1);
}

.compareButton {
  min-width: 226px;
  height: 100%;
  padding-left: 16px;
  border: 1px solid var(--header-compare-button-border-color);
  border-radius: 4px;
  background-color: var(--header-compare-button-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  overflow: hidden;
  cursor: pointer;
  transition: var(--transition);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &Text {
    @extend %text-ellipsis;

    color: var(--contrast);
    margin-bottom: 0;
    transition: var(--transition);
  }
}

.archiveText {
  @extend %description;

  color: var(--contrast);
  opacity: 0.5;
  padding-left: 16px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.containerWrapper {
  width: 100%;
  max-height: 300px;
  background-color: var(--header-compare-button);
  overflow-y: auto;
}

.panel {
  background-color: transparent;
}

.item {
  &Wrapper {
    @extend %ghost-button;

    width: 100%;
    height: 32px;
    padding: 0 8px;
    display: flex;
    align-items: center;
  }

  &Text {
    color: var(--contrast);
    margin-bottom: 0;
  }
}

.itemWrapper {
  @extend %ghost-button;

  &__nested {
    padding-left: 24px;
  }
}

.itemsWrapper {
  background-color: transparent;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
  transition: var(--transition);

  &__opened {
    transform: rotate(-90deg);
  }
}

.icon {
  fill: var(--contrast);
  transition: var(--transition);
}
