.container {
  overflow-y: auto;

  :global {
    .ant-collapse {
      margin-left: 0;
    }

    .ant-collapse-item {
      .ant-collapse-header {
        height: 40px !important;
        display: flex;
        align-items: center;
        padding: 8px 16px 8px 14px !important;
        border: none !important;

        .ant-collapse-header-text {
          order: 1;
        }

        .ant-collapse-expand-icon {
          order: 2;
          display: flex;
          align-items: center;
        }

        .ant-collapse-extra {
          order: 3;
          margin: 0;
          flex: 1;
        }
      }
    }
  }

  &Editing {
    :global {
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 8px 16px 8px 8px !important;
        }
      }
    }
  }
}

.expandIcon {
  color: var(--contrast);
  transition: var(--transition);

  &Active {
    transform: rotate(180deg);
  }
}
