:root[data-theme='light'] {
  --main-layout-bg: var(--primary-1);
}

:root[data-theme='dark'] {
  --main-layout-bg: var(--primary-3);
}

.wrapper {
  width: 100%;
  height: 100%;
  min-width: 1280px;
  min-height: 715px;
  padding-left: 72px;
  display: flex;
  flex-flow: column;
  background-color: var(--main-layout-bg);
  transition: var(--transition);
  position: relative;

  @media (min-resolution: 101dpi) and (max-width: 1679px) {
    padding-left: 0;
  }

  &_portable {
    padding-left: 0;
  }
}

.contentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.childrenWrapper {
  width: 100%;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
