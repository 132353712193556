.container {
  min-width: 6px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: absolute;
  z-index: 99;
}

.rect {
  width: 100%;
  height: calc(100% - 14px);
  margin: 7px auto 7px;
  position: relative;
  left: 0;
  background: var(--brand-3);
  border: 1px solid var(--brand-1);
  transition-property: width, left;
  transition-duration: var(--transition);
}

svg.arrow {
  width: 6px;
  height: 6px;
  margin: 0 auto;
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);

  path {
    fill: var(--brand-1);
  }

  &Up {
    @extend .arrow;

    margin: auto auto 0;
    bottom: -7px;
    transform: translateX(-50%) rotate(180deg);
  }
}

.over {
  z-index: 99;

  .rect {
    width: calc(100% + 40px);
    left: -20px;
  }
}

.container {
  &.notAvailable {
    > .rect {
      border-color: var(--red-color);
      background-color: rgba(235, 87, 87, 0.2);

      > svg.arrow > path {
        fill: var(--red-color);
      }
    }
  }

  &.expandedLeft {
    transform: translateX(-100%);
  }
}
