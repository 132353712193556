@use 'src/styles/templates';
@use 'src/styles/typography';

:root[data-theme='light'] {
  --new-well-approach-header-border-color: rgba(0, 0, 0, 0.1);
}

:root[data-theme='dark'] {
  --new-well-approach-header-border-color: rgba(255, 255, 255, 0.1);
}

.wrapper {
  display: flex;
}

.approachWrapper {
  width: 100%;
  height: max-content;
  padding: 16px;
  border-radius: 8px;
  opacity: 1;
  background-color: var(--new-well-form-bg-color);
  transition: var(--transition);
}

.collapsingArea {
  padding-top: 16px;
  border-top: 1px solid var(--new-well-approach-header-border-color);
}

.inactiveApproach {
  opacity: 0.5;
}

.approachHeader {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightHeaderBlock {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fieldsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.deleteButton {
  height: max-content;
  padding: 4px;
  margin-top: 16px;
  margin-right: 4px;
}

.collapseButton {
  @extend %ghost-button;

  display: flex;
  align-items: center;

  &:hover {
    background-color: transparent;
  }
}

.arrowIcon {
  fill: var(--contrast);

  transform: rotate(90deg);
  transition: var(--transition);

  &__closed {
    transform: rotate(-90deg);
  }
}

.approachTitle {
  @extend .h3;

  vertical-align: middle;
  color: var(--contrast);
  height: 20px;
  transition: var(--transition);
}

.collapsingArea {
  &__collapsed {
    height: 0px;
    overflow: hidden;
  }
}

.approachIndexWrapper {
  width: max-content;
}

.infoIconButton {
  @extend %ghost-button;

  width: 20px;
  height: 20px;
}

.infoIcon {
  color: var(--contrast);
  transition: var(--transition);
}
