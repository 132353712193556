@import 'src/styles/typography';

:root[data-theme='light'] {
  --borehole-item-bg-color: var(--white-color);
}

:root[data-theme='dark'] {
  --borehole-item-bg-color: var(--primary-1);
}

.container {
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }

  .handlersContainer {
    display: flex;
    align-items: center;

    .dragHandler {
      color: var(--contrast);
      margin-right: 8px;
      cursor: move;
    }

    .deleteHandler {
      margin-right: 8px;
      cursor: pointer;
    }
  }
}

.conflictContainer {
  margin-left: 62px;
  margin-bottom: 16px;
}
