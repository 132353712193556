@use 'src/styles/typography';
@use 'src/styles/templates';

:root[data-theme='light'] {
  --new-value-conflict-select-border: var(--primary-3);
  --new-value-conflict-select-bg: transparent;
}

:root[data-theme='dark'] {
  --new-value-conflict-select-border: var(--primary-2);
  --new-value-conflict-select-bg: var(--primary-2);
}

.text {
  @extend %body-m;

  min-height: 60px;
  max-height: 60px;
  color: var(--contrast);
  max-width: 408px;
  transition: var(--transition);
}

.foundValue {
  @extend %body-l;

  color: var(--contrast);
  transition: var(--transition);
}

.selectText {
  @extend %caption;

  color: var(--contrast);
  opacity: 0.5;
}

.selectWrapper {
  display: block;
  margin-top: 24px;

  :global(.ant-select-single.ant-select-open .ant-select-selection-item) {
    color: var(--contrast);
    opacity: 0.3;
  }
}

.select {
  color: var(--contrast);
  width: 100%;
  height: 32px;
  border-radius: 4px;
  border: 1px solid var(--new-value-conflict-select-border);
  background-color: var(--new-value-conflict-select-bg);
  cursor: pointer;

  &:focus-within {
    outline: 1px solid var(--contrast);
  }
}

.tooltipButton {
  @extend %ghost-button;

  width: 24px;
  max-height: 24px;
}

.buttonsWrapper {
  width: 310px;
  margin: 0 auto;
  margin-top: 48px;
}

.button {
  color: white;
  width: 100%;

  &:hover {
    color: white;
  }
}

.icon {
  stroke: var(--contrast);
}

.buttonWrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}
