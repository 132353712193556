@use '../../../../styles/templates';
@use '../../../../styles/typography';
@use '../../../../styles/variables';

:root[data-theme='light'] {
  --well-card-background-color: #ffffff;
  --well-card-border-color: var(--primary-2);
  --well-icon-opacity: 0.75;
}

:root[data-theme='dark'] {
  --well-card-background-color: var(--primary-1);
  --well-card-border-color: var(--primary-3);
  --well-icon-opacity: 1;
}

.dataItem {
  position: absolute;
  z-index: 10;
  touch-action: none;
  opacity: 1;
  transition: opacity var(--transition);
  overflow: hidden;
}

.wellCard {
  height: 100%;
  left: 2px;
  background-color: var(--well-card-background-color);
  border-left: 1px solid var(--well-card-border-color);
  border-right: 1px solid var(--well-card-border-color);
  cursor: pointer;
  box-shadow: none;

  &.wellCard {
    width: calc(100% - 4px);
  }

  &Comparing {
    background-color: var(--comparing-background);
  }

  &InnerComparing:nth-child(n) {
    color: var(--white-color);
    opacity: 1;
  }
}

.wellIcon {
  min-width: 16px;
  height: 16px;
  margin-left: 4px;
  opacity: var(--well-icon-opacity);

  path:nth-child(n) {
    stroke: currentColor;
  }
}

.tooltip {
  &Inner {
    @extend .body-m;

    width: 348px;
  }

  &AttributesContainer {
    width: 100%;
    margin-left: 28px;
  }

  &Label {
    @extend .body-m-bold;

    margin-right: 4px;
    color: var(--contrast);
    opacity: 0.5;
  }

  &Icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: -28px;

    &Rig path {
      stroke: var(--contrast);
    }

    &Search {
      stroke: var(--contrast);

      circle {
        fill: var(--search-icon-background-color);
      }
    }
  }

  &Row {
    margin: 4px 0;
    display: flex;
    flex-flow: row;
    align-items: center;
    position: relative;
  }

  &Attribute {
    &Container {
      display: flex;
      flex-flow: row wrap;
    }

    &Group {
      width: 100%;
      display: flex;
      flex-flow: column;

      &:not(:last-child) {
        border-bottom: 1px solid var(--contrast-10);
      }
    }

    &Row {
      margin: 0;
    }

    &Label {
      margin-right: 4px;
    }
  }
}

.dragging {
  z-index: 101;
}
