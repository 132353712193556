@use 'src/styles/templates';

.button {
  width: 100%;
  margin: 0 auto -4px;
  padding: 8px 8px;
  transition: background-color var(--transition);
}

.buttonText {
  text-align: center;
  color: var(--brand-1);
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-color: transparent;
  transition: var(--transition);

  &:hover {
    text-decoration-color: var(--brand-1);
  }
}
