@import 'src/styles/typography';

[data-theme='light'] {
  --pad-card-background-color: var(--white-color);
}

[data-theme='dark'] {
  --pad-card-background-color: rgba(26, 32, 44, 0.72);
}

.container {
  width: 100%;
  padding: 0 4px 16px 4px;
  display: flex;
  flex-flow: column;
  background-color: var(--pad-card-background-color);
  border-radius: 8px;
}

.header {
  @extend .body-m-bold;

  margin: 16px 16px 16px 10px;
  color: var(--contrast);
}

.attributes {
  display: flex;
  align-items: center;
}

.attribute {
  width: 16px;
  height: 16px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.well {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
