@import 'src/styles/typography';

:root[data-theme='light'] {
  --secondary-button-border-color: var(--brand-1);
  --secondary-button-bg-color: var(--white-color);
  --secondary-button-text-color: var(--brand-1);
  --secondary-button-bg-color-hover: var(--primary-2);
  --tertiary-button-border-color: var(--brand-1);
  --tertiary-button-bg-color-hover: var(--white-color);
}

:root[data-theme='dark'] {
  --secondary-button-border-color: var(--primary-3);
  --secondary-button-bg-color: var(--primary-3);
  --secondary-button-text-color: var(--contrast);
  --secondary-button-bg-color-hover: rgba(35, 43, 55, 1);
  --tertiary-button-border-color: transparent;
  --tertiary-button-bg-color-hover: var(--primary-2);
}

.button {
  @extend .button-m;

  padding: 4px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  gap: 8px;
  border-radius: var(--button-border-radius);
  background-color: var(--blue);
  text-decoration: none;
  cursor: pointer;
  transition: background-color var(--transition);

  .buttonIconWrapper {
    display: flex;
    align-items: center;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover {
    background-color: var(--blue-dark);
  }

  &:active {
    background-color: var(--blue-light);
  }

  &Inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  &Reversed {
    flex-flow: row-reverse;
  }

  &Text,
  &Link {
    padding-top: 2px;
    padding-bottom: 2px;
    color: var(--brand-1);

    &:hover > .buttonInner {
      &::after {
        background-color: currentColor;
      }
    }

    &:disabled {
      .buttonInner::after {
        display: none;
      }
    }

    .buttonInner {
      width: max-content;
      display: flex;
      flex-flow: row;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        transition: background-color var(--transition);
      }
    }

    .buttonIconWrapper {
      display: flex;
      margin-left: 6px;
    }
  }

  &Link {
    .buttonInner::after {
      background-color: currentColor;
    }
  }

  &Danger,
  &Success,
  &Primary,
  &Secondary,
  &Tertiary {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: var(--transition);

    .buttonIconWrapper {
      display: flex;
      align-items: center;
      padding: 0;
    }
  }

  &Danger,
  &Success {
    opacity: 1;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  &Danger {
    background: var(--red-color);

    &:hover {
      background: var(--red-color);
    }
  }

  &Success {
    background: var(--green-color);
    color: var(--white-color);

    &:hover {
      background: var(--green-color);
    }
  }

  &Primary {
    background: var(--brand-1);
    background-color: var(--brand-1);

    &:hover {
      background: var(--brand-2);
      background-color: var(--brand-2);
    }

    &:disabled {
      background-color: var(--brand-3);
    }
  }

  &Secondary,
  &Tertiary {
    transition: var(--transition);
  }

  &Secondary {
    color: var(--secondary-button-text-color);
    border: 1px solid var(--secondary-button-border-color);
    background: var(--secondary-button-bg-color);

    &:hover {
      background: var(--secondary-button-bg-color-hover);
      border-color: var(--secondary-button-bg-color-hover);
    }

    &:disabled {
      background: var(--secondary-button-bg-color);
      border-color: var(--secondary-button-bg-color);
      opacity: 0.4;
    }
  }

  &Tertiary {
    padding: 4px 8px;
    background: var(--tertiary-button-bg-color-hover);
    border: 1px solid var(--tertiary-button-border-color);
    color: var(--brand-1);
  }

  &Loader {
    width: 20px;
    height: 20px;
    margin: 4px 0 auto 0;
    color: inherit;

    :global {
      .ant-spin-dot-item {
        background-color: currentColor;
      }
    }
  }
}
