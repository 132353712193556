@import 'src/styles/templates';
@import 'src/styles/typography';

[data-theme='light'] {
  --search-input-background-color: var(--white-color);
  --search-outline-color: var(--brand-1);
  --search-focused-shadow: none;
  --chart-search-border: 1px solid var(--primary-3);
}

[data-theme='dark'] {
  --search-input-background-color: var(--primary-1);
  --search-outline-color: var(--brand-2);
  --search-focused-shadow: 0px 4px 4px rgba(58, 54, 219, 0.25);
  --chart-search-border: 1px solid transparent;
}

.container {
  width: 100%;
  padding: 4px 4px 4px 8px;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 4px;
  background-color: var(--search-input-background-color);
  border: var(--chart-search-border);
  transition: var(--transition);

  &Focused {
    border: 1px solid var(--search-outline-color);
    box-shadow: var(--search-focused-shadow);
  }
}

.input {
  @extend .body-m;

  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  appearance: none;

  &:focus ~ .placeholderWrapper {
    opacity: 0.5;
  }

  &Wrapper {
    height: 20px;
    margin: auto 0;
    position: relative;
    width: 100%;
  }
}

.icon {
  width: 24px;
  height: 24px;

  path {
    fill: var(--contrast);
  }
}

.suffix,
.prefix {
  width: 24px;
  height: 24px;
}

.prefix {
  margin-right: 8px;
}

.suffix {
  margin-left: 8px;
  position: relative;
}

.placeholder {
  @extend %text-ellipsis;

  &Wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: opacity var(--transition);
  }
}

.clear {
  &Button {
    width: 24px;
    height: 24px;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;

    .icon {
      path {
        stroke: var(--contrast);
      }
    }
  }
}
