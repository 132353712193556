.titleContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .title {
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
    margin-top: -0.5em;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .optionButton {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border: none;
    outline: none;
    background: transparent;
    padding: 6px;
    transform: translateY(-6px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    border-radius: 4px;
    cursor: pointer;

    .filterIcon,
    .arrowIcon {
      display: none;
      height: 16px;
      margin-right: 4px;
    }

    .arrowIcon {
      &Asc {
        transform: rotate(180deg);
      }
    }

    &Active {
      background: var(--brand-1);
      padding: 6px;
      align-items: center;
      color: var(--white-color);
    }

    &Filtered {
      .filterIcon {
        display: block;
      }
    }

    &Sorting {
      .arrowIcon {
        display: block;
      }
    }
  }
}

.resizeHandler {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  right: 0;
  background: transparent;
  cursor: col-resize;
  z-index: 10;
}
