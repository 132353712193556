@use 'src/styles/typography';

:root[data-theme='light'] {
  --group-container-border-color: var(--primary-3);
}

:root[data-theme='dark'] {
  --group-container-border-color: rgba(255, 255, 255, 0.1);
}

.wrapper {
  height: 100%;
  padding: 0 24px 0;
  overflow-y: scroll;
}

.saveButton {
  margin: 14px 24px;
}
