.mainContentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  &_hidden {
    display: none;
  }
}

.tableContainer {
  padding: 16px 0 0px 16px;
  height: 100%;
}
