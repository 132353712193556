@use 'src/styles/typography';

:root[data-theme='light'] {
  --compared-cell-bg-color: rgba(58, 54, 219, 0.2);
}

:root[data-theme='dark'] {
  --compared-cell-bg-color: rgba(96, 92, 255, 0.4);
}

.cellWrapper {
  padding: 8px;
  width: 100%;
  height: 100%;

  &Compare {
    background: var(--compared-cell-bg-color);
  }

  &Resizable {
    cursor: col-resize;
  }
}
