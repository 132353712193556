:root[data-theme='light'] {
  --collapse-bg-color: var(--white-color);
  --collapse-header-border-color: var(--secondary-1);
}

:root[data-theme='dark'] {
  --collapse-bg-color: var(--primary-1);
  --collapse-header-border-color: var(--secondary-2);
}

.container {
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  :global {
    .ant-collapse {
      border-radius: 8px;
      background: var(--collapse-bg-color);
      border: none !important;
    }

    .ant-collapse-item {
      .ant-collapse-header {
        height: 60px;
        display: flex;
        align-items: center;
        padding: 0 16px 0 30px;
        border-bottom: 1px solid transparent;

        .ant-collapse-expand-icon {
          display: flex;
          align-items: center;
        }
      }

      .ant-collapse-content {
        padding: 16px;
      }

      &-active {
        .ant-collapse-header {
          border-bottom: 1px solid var(--collapse-header-border-color);
        }
      }
    }
  }
}

.expandIcon {
  color: var(--contrast);
  transition: var(--transition);

  &Active {
    transform: rotate(180deg);
  }
}

.preloaderContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
