:root[data-theme='light'] {
  --row-background-color: #ffffff;
}

:root[data-theme='dark'] {
  --row-background-color: var(--primary-1);
}

.data {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.rowGroup {
  background-color: var(--row-background-color);
  margin-bottom: 2px;
}

.row {
  width: 100%;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  z-index: 5;

  &DataWrapper {
    width: 100%;
    position: relative;
  }

  &DataItem {
    width: fit-content;
    position: absolute;
    z-index: 5;
  }

  &HeaderWrapper {
    width: 100%;
    position: absolute;
    background-color: var(--row-background-color);
    z-index: 7;
  }
}

.arrowIcon {
  transform: rotate(90deg);
}
