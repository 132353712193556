@import 'src/styles/templates';
@import 'src/styles/typography';

:root[data-theme='light'] {
  --header-grid-line-color: var(--secondary-1);
  --line-blend-mode: miltiply;
}

:root[data-theme='dark'] {
  --header-grid-line-color: var(--secondary-2);
  --line-blend-mode: none;
}

.columns {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 264px;
  right: 0;
  overflow: hidden;
  user-select: none;

  &::before {
    content: '';
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    top: 42px;
    bottom: 0;
    background-color: var(--header-grid-line-color);
    z-index: 10;
  }

  &Wrapper {
    @extend %grabbing;
  }

  &HeaderTitle {
    margin: 0 auto;
  }
}

.currentDate {
  &Wrapper {
    width: 1px;
    height: 100%;
    margin-top: 42px;
    position: relative;
    z-index: 15;

    &::after {
      content: '';
      width: 1px;
      height: 100%;
      display: block;
      position: absolute;
      background: repeating-linear-gradient(
        var(--contrast) 0,
        var(--contrast) 10px,
        transparent 11px,
        transparent 20px
      );
      mix-blend-mode: var(--line-blend-mode);
    }
  }

  &Label {
    @extend .description;

    position: absolute;
    top: -16px;
    transform: translateX(-50%);
    color: var(--contrast-transparent);
  }
}
