@use 'src/styles/templates';
@import 'src/styles/typography';

:root[data-theme='light'] {
  --compare-sidebar-switcher-wrapper: transparent;
  --compare-sidebar-switcher-bg: var(--primary-2);
}

:root[data-theme='dark'] {
  --compare-sidebar-switcher-wrapper: var(--primary-2);
  --compare-sidebar-switcher-bg: var(--primary-1);
}

.container {
  position: relative;
}

.switcherWrapper {
  width: 100%;
  min-height: 64px;
  padding-left: 24px;
  background-color: var(--compare-sidebar-switcher-wrapper);
  display: flex;
  align-items: center;
  transition: var(--transition);
}

.overwriteSwitcherBgColor {
  background-color: var(--compare-sidebar-switcher-bg);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  padding: 8px 24px 28px 24px;
}

.icon {
  margin-right: 8px;
  stroke: var(--contrast);
  transition: var(--transition);
}

.description {
  @extend %body-m;
  margin-top: 8px;
  margin-bottom: 12px;
  color: var(--contrast);
  opacity: 0.75;
  transition: var(--transition);

  &Empty {
    text-align: center;
    margin-top: 24px;
    opacity: 0.5;
  }
}

.loader {
  margin: auto;

  &Container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
  }
}

.container {
  max-height: 100%;
  flex-grow: 1;
  overflow-y: auto;
}
