.search {
  margin-bottom: 16px;
}

.padsContainer {
  flex: 1;
  max-height: 100%;
  position: relative;
  overflow-y: auto;
  padding: 16px 16px 16px 24px;
}

.buttonsGroup {
  padding: 24px 16px 24px 24px;
  display: flex;
  margin: 0 -8px;

  .button {
    margin: 0 8px;
    width: 100%;
  }
}

.loader {
  margin: auto;

  &Container {
    width: calc(100% - 16px * 2);
    position: absolute;
    top: 48px;
    bottom: 0;
    display: flex;
    z-index: 40;
  }
}
