@use 'src/styles/templates';

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.infoIconButton {
  @extend %ghost-button;

  width: 20px;
  height: 20px;
}

.infoIcon {
  color: var(--contrast);
  transition: var(--transition);
}
