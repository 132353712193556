@import 'src/styles/typography';
.card {
  &HeaderWrapper {
    @extend .fontBase;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: var(--contrast);
  }

  &ContentWrapper {
    @extend .subTextFont;

    width: 100%;
    color: var(--secondary-1);
  }

  &IconWrapper {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &Interactive {
    &:hover {
      cursor: pointer;
    }
  }
}
