@use 'src/styles/typography';

.modal {
  width: 456px;
}

.image {
  width: 124px;
  height: 160px;
  margin-bottom: 24px;
}

.title {
  @extend %h2;
  color: var(--contrast);
}

.descriptionParagraph {
  @extend %body-l;
  margin: 24px 0;
  color: var(--contrast);

  &:last-of-type {
    margin-bottom: 48px;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  .button {
    margin: 8px 0;
    width: 100%;
  }
}
