@import '../../../../styles/templates';

.container {
  height: 24px;
  margin-bottom: 5px;
  display: flex;
  text-align: center;
}

.title {
  @extend %text-ellipsis;

  width: 100%;
  margin: auto 8px;
}

.emptyValue {
  color: var(--contrast-transparent);
}
