@use 'src/styles/typography';

.modal {
  width: 456px;
}

.image {
  width: 196px;
  height: 164px;
  margin-bottom: 24px;
}

.title {
  @extend %h2;
  color: var(--contrast);
}

.description {
  @extend %body-l;
  margin: 24px 0 48px 0;
  color: var(--contrast);
}

.buttonsWrapper {
  display: flex;
  margin: 0 -12px;

  .button {
    margin: 0 12px;
    width: 100%;
  }
}
