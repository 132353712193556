@use 'src/styles/typography';

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.title {
  @extend %h3;

  color: var(--contrast);
  transition: var(--transition);
}

.arrowIcon {
  margin-right: 8px;
}

.buttonsWRapper {
  display: flex;
  gap: 16px;
}
