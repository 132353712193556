@import 'fonts';

.fontBase {
  font: {
    family: Inter;
    style: normal;
  }
}

.mega {
  @extend .fontBase;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.h1 {
  @extend .fontBase;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.h2 {
  @extend .fontBase;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.h3 {
  @extend .fontBase;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

.h4 {
  @extend .fontBase;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
}

.body-l {
  @extend .fontBase;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.body-m {
  @extend .fontBase;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.body-m-bold {
  @extend .fontBase;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.body-m-del {
  @extend .fontBase;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: line-through;
}

.body-xxs {
  @extend .fontBase;
  font-weight: 400;
  font-size: 8px;
  line-height: 16px;
}

.link-m {
  @extend .fontBase;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
}

.caption {
  @extend .fontBase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.link-caption {
  @extend .fontBase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
}

.description {
  @extend .fontBase;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;
}

.button-m {
  @extend .fontBase;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.button-m-hover {
  @extend .fontBase;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: underline;
}

.subTextFont {
  @extend .fontBase;

  font: {
    size: 12px;
    weight: 500;
  }
  line-height: 133%;
}

%h1 {
  @extend .fontBase;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

%h2 {
  @extend .fontBase;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

%h3 {
  @extend .fontBase;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}

%body-l {
  @extend .fontBase;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

%body-m {
  @extend .fontBase;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

%body-m-bold {
  @extend .fontBase;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

%body-xxs {
  @extend .fontBase;
  font-weight: 400;
  font-size: 8px;
  line-height: 16px;
}

%button-m {
  @extend .fontBase;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

%description {
  @extend .fontBase;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.005em;
}

%caption {
  @extend .fontBase;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
