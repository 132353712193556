.container {
  display: flex;
  align-items: center;
  margin-left: 24px;

  .button {
    height: 28px;
    margin: 0 8px;

    &:first-child {
      margin: 0 8px 0 0;
    }

    &:last-child {
      margin: 0 26px 0 8px;
    }
  }
}
