.wrapper {
  width: calc(60% - 32px);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headersWrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.contentWrapper {
  padding-bottom: 16px;
  overflow-y: auto;
}

.systemItem {
  padding-right: 8px;
}

.userItem {
  padding-left: 8px;
}

.arrow {
  fill: var(--contrast);
  transition: var(--transition);
}
