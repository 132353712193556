@import 'src/styles/templates';

:root {
  --select-area-left: 0;
  --select-area-width: 0;
  --select-area-right: 0;

  &[data-theme='dark'] {
    --select-background-color: rgba(96, 92, 255, 0.4);
  }

  &[data-theme='light'] {
    --select-background-color: rgba(58, 54, 219, 0.2);
  }
}

.select {
  &Container {
    width: var(--select-area-width);
    height: 100%;
    position: absolute;
    left: var(--select-area-left);
    background-color: var(--select-background-color);
    touch-action: none;
    @extend %grabbing;
  }

  &DragElement {
    cursor: col-resize;

    width: 12px;
    height: 100%;
    display: flex;
    position: absolute;
    left: var(--select-area-left);
    touch-action: none;

    &Inner {
      width: 8px;
      height: 12px;
      margin: auto;
      position: absolute;
      top: 50%;
      display: flex;
      border-radius: 2px;
      background-color: var(--brand-1);
    }

    &Right {
      left: var(--select-area-right);
    }

    &Right &Inner {
      transform: translateX(-100%);
    }
  }

  &DragIcon {
    width: 4px;
    height: 6px;
    margin: auto;
  }
}
