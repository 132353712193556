@use 'src/styles/typography';

:root[data-theme='light'] {
  --add-well-button-bg-color: rgba(170, 195, 250, 1);
}

:root[data-theme='dark'] {
  --add-well-button-bg-color: rgba(96, 92, 255, 0.4);
}

.button {
  margin: 16px 0 0;
  width: 100%;
  height: 16px;
  border-radius: 4px;
  background: var(--add-well-button-bg-color);
  border: 1px dashed var(--brand-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .icon {
    color: var(--brand-1);
    width: 16px;
    height: 16px;
  }
}
