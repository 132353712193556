.wrapper {
  margin-top: 40px;
  padding-left: 24px;
}

.switcherWrapper {
  margin-bottom: 40px;
  transition: var(--transition);

  &__compact {
    margin-bottom: 24px;
  }
}
