@import 'src/styles/typography';

.chart {
  grid-area: chart;
  width: calc(100% - 16px);
  height: unset;
  margin: 16px 0 8px 16px;
  position: relative;
  display: grid;
  grid-template-areas:
    'search header summary'
    '. . summary'
    'rowHeaders data summary'
    'indicators indicators indicators';
  grid-template-columns: 264px auto 0;
  grid-template-rows: 40px 2px auto min-content;
  flex-grow: 1;

  &Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
  }

  &Header {
    grid-area: header;
  }

  &DataContainer {
    grid-area: data;
    margin-bottom: auto;
    display: flex;
    flex-flow: row nowrap;
    user-select: none;
  }

  &Indicators {
    grid-area: indicators;
    margin-top: auto;
  }

  &DataHeaders {
    grid-area: rowHeaders;
  }

  &DataItemsContainer {
    width: 100%;
  }

  &DataCard {
    margin-top: 24px;
    background-color: var(--primary-1);

    &Dark {
      background-color: var(--primary-3);
    }
  }

  &HeadersColumn {
    min-width: 264px;
    max-width: 264px;
  }

  &RowGroup {
    background-color: var(--primary-1);

    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  &DataWrapper {
    width: 100%;
    position: relative;
  }

  &LastRowInGroup {
    border-bottom-left-radius: var(--table-border-radius);
    overflow: hidden;
  }

  &Search {
    grid-area: search;
  }

  &FullScreenButtons {
    position: absolute;
    top: -16px;
    right: 0;
    z-index: 20;
  }
}

.timeline {
  height: 42px;
  display: grid;
  grid-template-areas: 'periodButtons timeline';
  grid-template-columns: 264px auto;
  align-items: center;

  &Wrapper {
    grid-area: timeline;
  }

  &PeriodsToggle {
    grid-area: periodButtons;
    width: 100%;

    & .timelinePeriodButton {
      @extend .caption;

      padding: 6px 8px;
      flex-grow: 1;
    }
  }
}

.loader {
  margin: auto;
}

.dataUpdatingLoader {
  margin: auto;

  &Container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    background-color: var(--backdrop-transparent);
    z-index: 20;
  }
}

.filtersModal {
  min-width: 930px;
}
