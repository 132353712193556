:root[data-theme='light'] {
  --filters-button-color: rgba(58, 54, 219, 0.8);
}

:root[data-theme='dark'] {
  --filters-button-color: var(--red-color);
}

.container {
  margin: auto 0 2px;
  display: flex;
}

.search {
  height: 32px;
  margin: auto 0;
  color: var(--contrast);
}

.button {
  width: 24px;
  height: 24px;
  padding: 0;

  &Highlighted {
    background-color: var(--filters-button-color);

    .icon {
      path,
      ellipse {
        stroke: var(--white-color);
      }
    }
  }
}

.icon {
  width: 24px;
  height: 24px;

  path,
  ellipse {
    stroke: var(--contrast);
  }
}
