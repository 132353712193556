.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  z-index: 60;
  transition: var(--transition);

  &__opened {
    opacity: 1;
  }
}
