:root[data-theme='light'] {
  --chart-name-color: #06152b;
  --chart-border-bottom-bg-color: #d2dffa;
  --chart-border-top-bg-color: #d2dffa;
}

:root[data-theme='dark'] {
  --chart-name-color: #ffffff;
  --chart-border-bottom-bg-color: rgba(255, 255, 255, 0.5);
  --chart-border-top-bg-color: #ffffff1a;
}

.chart {
  min-height: 352px;

  &BarType {
    min-height: 393px;
  }
}

.chartHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--chart-border-top-bg-color);
  padding-bottom: 10px;
  column-gap: 30px;
  margin-bottom: 15px;

  h3 {
    font-weight: 700;
    white-space: nowrap;
    color: var(--chart-name-color);
  }
}

.legend {
  display: flex;
  width: 100%;
  column-gap: 24px;
  margin-right: 24px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 4px;
  }
}

.labelWrapper {
  display: flex;
  overflow-x: hidden;
}

.switcher {
  min-width: 44px;
}

.bottomLegend {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 24px;
  border-top: 1px solid var(--chart-border-bottom-bg-color);
  padding-top: 8px;
  max-width: 100%;
  overflow-y: auto;

  &::before,
  &::after {
    content: '';
    margin: auto;
  }

  &::-webkit-scrollbar {
    height: 4px;
  }
}

.bottomLabelItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2px;
}

.labelCircleColor {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.bottomLabel {
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: var(--contrast);
}
