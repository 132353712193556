@use 'src/styles/typography';

.wrapper {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.textWrapper {
  margin-top: 36px;
  display: flex;
  align-items: flex-end;
}

.text {
  @extend %body-l;
  color: var(--contrast);
  height: max-content;
  transition: var(--transition);
  user-select: none;
}

.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: var(--contrast);
  vertical-align: middle;
  transition: var(--transition);
}

.uploadImg {
  margin-top: 44px;
  user-select: none;
}

.uploadImg #test {
  stroke: red;
}

.arrow {
  position: absolute;
  top: 7px;
  right: 21px;
}
