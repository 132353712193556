.dragAreaContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dataFieldWrapper {
  width: 50%;
  position: relative;
}

.userDataFieldWrapper {
  width: calc(50% - 32px);
  margin-right: 32px;
  user-select: none;

  &__grabbable {
    cursor: grab;
  }
}

.userItem {
  padding-left: 8px;
  transition: var(--transition);

  &__hovered {
    background-color: var(--matching-row-bg);
  }
}
