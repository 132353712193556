@use 'src/styles/typography';

:root[data-theme='light'] {
  --group-container-border-color: var(--primary-3);
}

:root[data-theme='dark'] {
  --group-container-border-color: rgba(255, 255, 255, 0.1);
}

.wrapper {
  height: 100%;
  padding: 0 24px 0;
  overflow-y: scroll;

  .groupTittle {
    @extend %h3;
    color: var(--contrast);
  }

  .collapse {
    margin: 12px 0;

    &:last-of-type {
      margin-bottom: 8px;

      .settingGroup {
        border-bottom: none;
      }
    }
  }

  .settingGroup {
    border-top: 1px solid var(--group-container-border-color);
    border-bottom: 1px solid var(--group-container-border-color);
    padding: 8px 0;
    margin-top: 8px;
  }
}

.saveButton {
  margin: 14px 24px;
}
