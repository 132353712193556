@use 'src/styles/typography';

.wrapper {
  padding: 8px 0;
  display: flex;
  align-items: center;
  color: var(--contrast);

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      outline: none;

      &:focus-visible {
        outline: auto;
      }
    }
  }

  .columnName {
    @extend %body-m;
    margin-left: 8px;
    flex: 1;
  }

  .moveIcon {
    margin-right: 16px;
    cursor: move;
  }

  .fixButton {
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--contrast);
    opacity: 0.5;

    &Fixed {
      opacity: 1;

      .icon {
        fill: var(--contrast);
      }
    }
  }
}
