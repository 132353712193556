@use 'src/styles/typography';

:root[data-theme='light'] {
  --subtitle-text-color: var(--contrast);
}

:root[data-theme='dark'] {
  --subtitle-text-color: rgba(255, 255, 255, 0.6);
}

.container {
  display: flex;
  align-items: center;

  .textAttributes {
    display: flex;
    flex: 1;

    .title {
      @extend%h3;
      color: var(--contrast);
      margin-right: 24px;
    }

    .subtitle {
      @extend%body-m;
      color: var(--subtitle-text-color);
      margin-right: 24px;
    }
  }

  .iconAttributes {
    display: flex;
    align-items: center;

    .icon {
      width: 16px;
      height: 16px;

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
