@use 'src/styles/typography';

.wrapper {
  padding: 8px 0;
  display: flex;
  align-items: center;
  color: var(--contrast);

  .moveIcon {
    margin-right: 16px;
    cursor: move;
  }

  .columnName {
    @extend %body-m;
    flex: 1;
  }
}
