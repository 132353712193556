@use 'src/styles/typography';

:root[data-theme='light'] {
  --tooltip-search-bg-color: var(--white-color);
  --tooltip-search-placeholder-color: rgba(6, 21, 43, 0.5);
}

:root[data-theme='dark'] {
  --tooltip-search-bg-color: var(--primary-3);
  --tooltip-search-placeholder-color: var(--secondary-1);
}

.search {
  @extend%body-m;
  width: 100%;
  height: 32px;
  background: var(--tooltip-search-bg-color);
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &::placeholder {
    color: var(--tooltip-search-placeholder-color);
  }
}

.filtersContainer {
  overflow-y: scroll;
  max-height: 300px;
}

.checkItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.bottomButtonsContainer {
  display: flex;
  align-items: center;
  padding: 8px 0;

  .button {
    &:first-child {
      margin-right: 8px;
    }
  }
}
