[data-theme='light'] {
  --well-colored-card-background-color: var(--white-color);
}

[data-theme='dark'] {
  --well-colored-card-background-color: var(--primary-1);
}

.card {
  background-color: var(--well-colored-card-background-color);

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.container {
  width: 100%;
  padding: 8px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.iconRejected {
  width: 24px;
  height: 24px;
  margin-right: 8px;

  circle {
    fill: var(--red-color);
  }
}
