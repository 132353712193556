@use 'src/styles/typography';

.buttonsWrapper {
  width: 100%;
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
}

.text {
  @extend %body-m;

  color: var(--contrast);
}
