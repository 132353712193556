.container {
  display: flex;
  align-items: center;

  .importOccurredButtons {
    display: flex;
    align-items: center;
    margin: 0 -8px;

    > * {
      margin: 0 8px;
    }
  }
}
