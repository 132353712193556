.row {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 8px 0;
}

.label {
  margin-left: 16px;
}
