@import 'src/styles/typography';

.container {
  padding: 16px 16px 16px 24px;
  background-color: inherit;
  overflow-y: auto;
}

.affectedRigOperationsListContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.descriptionText {
  @extend .body-m;

  margin: 16px 0;
  color: var(--contrast);

  &:first-child {
    margin-top: 0;
  }
}

.rigTitle {
  @extend .body-m-bold;

  margin: 0 0 16px;
  color: var(--contrast);
}

.footer {
  margin-top: auto;
  padding: 16px 16px 16px 24px;
  background-color: inherit;

  display: flex;
  gap: 24px;
}

.button {
  width: 100%;
}
