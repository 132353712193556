@use 'src/styles/typography';

:root[data-theme='light'] {
  --group-container-border-color: var(--primary-3);
}

:root[data-theme='dark'] {
  --group-container-border-color: rgba(255, 255, 255, 0.1);
}

.controlBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 24px;

  &Hint {
    color: var(--contrast);
    opacity: 0.6;
  }

  &Button {
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.wrapper {
  height: 100%;
  padding: 0 24px 0;
  overflow-y: scroll;

  .groupTittle {
    @extend %h3;
    color: var(--contrast);
  }

  .collapse {
    margin: 12px 0;

    &:last-of-type {
      margin-bottom: 8px;

      .columnsGroup {
        border-bottom: none;
      }
    }
  }

  .columnsGroup {
    border-top: 1px solid var(--group-container-border-color);
    border-bottom: 1px solid var(--group-container-border-color) !important;
    margin-top: 8px;

    .columnName {
      margin: 8px 0;
    }
  }
}
