@import '../../../../styles/typography';

[data-theme='light'] {
  --filters-scrollbar-thumb-color: rgba(170, 195, 250, 0.5);
  --filters-scrollbar-track-color: rgba(170, 195, 250, 0.3);
}

[data-theme='dark'] {
  --filters-scrollbar-thumb-color: rgba(26, 32, 44, 0.5);
  --filters-scrollbar-track-color: rgba(26, 32, 44, 0.3);
}

.modal {
  min-height: 720px;
  padding: 16px 8px 16px 16px;
  display: flex;
  flex-flow: column;
  background-color: var(--primary-1);
}

.form {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.fields {
  height: 100%;
  padding: 4px 0 8px 8px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;

    &-track {
      background-color: var(--filters-scrollbar-track-color);
    }

    &-thumb {
      background-color: var(--filters-scrollbar-thumb-color);
    }
  }

  &Inner {
    position: relative;
    display: flex;
    flex-flow: row wrap;
  }
}

.header {
  padding-bottom: 16px;
  padding-left: 8px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  &Title {
    @extend .h1;

    color: var(--contrast);
  }
}

.footer {
  margin-top: auto;
  padding-top: 16px;
  display: flex;
  flex-flow: row;

  &Button {
    width: 100%;
    border-radius: 4px;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.heading {
  @extend .body-m-bold;

  color: var(--contrast);
}

.block {
  margin-bottom: 32px;
  display: flex;
  flex-flow: column;

  &Label {
    @extend .caption;

    margin-bottom: 2px;
    color: var(--contrast-transparent);
  }
}

.fieldLabel {
  color: var(--contrast);
}

.select {
  @extend .block;

  width: calc(50% - 42px);
  margin: 16px 42px 18px 0;
}

.switch {
  @extend .block;
  @extend .row;

  width: 100%;

  .fieldLabel {
    @extend .heading;

    margin: auto 22px auto 0;
  }
}

.row {
  display: flex;
  flex-flow: row;
}

.loader {
  &Container {
    display: flex;
  }
}

.buttonLoader {
  height: 14px;
  margin-left: 8px;
}

.multiselect {
  :global {
    .ant-select-focused {
      z-index: 1051;
    }
  }
}
