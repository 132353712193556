.button {
  display: flex;
  color: var(--contrast);
}

.loader {
  width: 20px;
  height: 20px;
  margin: 4px 0 auto 0;
  color: inherit;

  :global {
    .ant-spin-dot-item {
      background-color: currentColor;
    }
  }
}
