@import 'src/styles/typography';

[data-theme='light'] {
  --well-info-stage-background-color: var(--white-color);
  --well-info-stage-body-background-color: var(--primary-2);
}

[data-theme='dark'] {
  --well-info-stage-background-color: var(--primary-2);
  --well-info-stage-body-background-color: var(--primary-3);
}

.title {
  @extend .body-m-bold;

  color: var(--contrast);
}

.container {
  padding: 0;
  overflow: hidden;
  background-color: var(--well-info-stage-background-color);

  &WithBody {
    .header {
      padding-bottom: 8px;
      border-bottom: 1px solid var(--primary-1);
    }
  }
}

.row {
  padding: 16px 16px 16px 20px;
  display: grid;
  grid-template-columns: (176 / 519 * 100%) (254 / 519 * 100%) (70 / 519 * 100%);
  column-gap: 2px;
}

.header {
  @extend .row;
}

.body {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--well-info-stage-body-background-color);
}

.subRow {
  @extend .row;

  padding-top: 4px;
  padding-bottom: 4px;

  &Title {
    padding-left: 16px;
    color: var(--contrast);
  }
}

.text {
  @extend .body-m;

  color: var(--contrast);
  font-size: 13px;
}

.subText {
  color: var(--contrast-transparent);
}
