.wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.intervalWrapper {
  max-width: 456px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.dash {
  font-size: 8px;
  color: var(--contrast);
  transition: var(--transition);
}

.input {
  min-width: 180px;
  max-width: 220px;
}
