:root[data-theme='light'] {
  --close-cross-hover-color: #505050;
  --close-cross-color: var(--contrast);
}

:root[data-theme='dark'] {
  --close-cross-hover-color: var(--white-color);
  --close-cross-color: rgba(204, 210, 227, 1);
}

%text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%ghost-button {
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: inherit;
  }
}

%close-cross {
  @extend %ghost-button;

  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 12px;
    background-color: var(--close-cross-color);
    transition: background-color var(--transition);
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: var(--close-cross-hover-color);
    }
  }
}

%grabbing {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}
