.wrapper {
  width: 100%;
  height: 100%;

  input {
    margin-top: -3px;
    display: flex;
    background: transparent;
    border: none;
    border-radius: 4px;
    outline: none;
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--contrast);
    cursor: text;
  }

  &Disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
