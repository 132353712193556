@use 'src/styles/typography';

$preloaderBackColor: rgba(0, 0, 0, 0.3);

.preloaderWrapper {
  width: 100%;
  height: 100%;
  background-color: $preloaderBackColor;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

.titleWrapper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  @extend %h2;

  color: var(--contrast);
}

.conflictsCount {
  @extend %caption;

  color: var(--contrast);
  opacity: 0.6;
}

.contentWrapper {
  width: 408px;
  height: 376px;
  position: relative;
}

.conflictContentWrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: var(--transition);

  &__unmount {
    opacity: 0;
    transform: translateX(-100%);
  }

  &__mount {
    opacity: 1;
    transform: translateX(0%);
  }
}
