[data-theme='light'] {
  --pad-info-header-background-color: transparent;
}

[data-theme='dark'] {
  --pad-info-header-background-color: var(--primary-2);
}

.container {
  width: 600px;
  position: relative;
}

.header {
  height: 60px;
  padding: 16px;
  display: flex;
  background-color: var(--pad-info-header-background-color);
}

.tabs {
  margin: auto 0;
}

.descriptions {
  padding: 16px;
}

.loader {
  margin: auto;

  &Container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    background-color: var(--backdrop-transparent);
    z-index: 40;
  }
}

.edit {
  &Button {
    width: 28px;
    height: 28px;
    margin-left: auto;
  }

  &Icon {
    path {
      stroke: var(--contrast);
    }
  }
}
