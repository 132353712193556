:root[data-theme='light'] {
  --compared-cell-bg-color: rgba(58, 54, 219, 0.2);
  --error-cell-bg-color: rgba(235, 87, 87, 0.2);
}

:root[data-theme='dark'] {
  --compared-cell-bg-color: rgba(96, 92, 255, 0.4);
  --error-cell-bg-color: rgba(235, 87, 87, 0.2);
}

.cellWrapper {
  padding: 8px;
  height: 100%;
  transition: var(--transition);

  &Error {
    background: var(--error-cell-bg-color);
  }

  &Compare {
    background: var(--compared-cell-bg-color);
  }

  &Disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
