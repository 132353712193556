.wrapper {
  width: 100%;

  :global {
    .ant-select {
      display: block;
    }

    .ant-select-selection-item,
    .ant-select-selection-search,
    .ant-select-selection-search-input,
    .ant-select-show-search,
    .ant-select-selector {
      line-height: 16px !important;
      height: 16px !important;
    }

    .ant-select-show-search {
      position: relative;
    }

    .ant-select-selection-search {
      left: 0 !important;
    }

    .ant-select-arrow,
    .ant-select-clear {
      color: var(--contrast);
      position: absolute;
      top: 50%;
      right: 0;
    }

    .ant-select-arrow {
      display: block;
    }

    .ant-select-clear {
      display: none;
    }

    :hover {
      .ant-select-arrow {
        display: none;
      }

      .ant-select-clear {
        display: block;
      }
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
    }

    .ant-select-single.ant-select-open .ant-select-selection-item {
      color: var(--contrast);
    }
  }
}
