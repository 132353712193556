@import 'src/styles/variables';

.container {
  width: 100%;
}

.row {
  height: 12px;
  border-radius: 4px;
  animation: skeleton-loading 1s linear infinite alternate;

  &:not(:last-child) {
    margin-bottom: 6px;
  }
}
