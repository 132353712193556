@use 'src/styles/typography';

.container {
  display: flex;
  align-items: center;

  .title {
    @extend%body-m-bold;
    color: var(--contrast);
    margin-right: 24px;
  }

  .attributes {
    display: flex;
    align-items: center;
  }

  .attribute {
    width: 16px;
    height: 16px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
