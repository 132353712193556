.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .dragHandler {
    margin-right: 16px;
    cursor: move;
  }

  .deleteHandler {
    margin-right: 16px;
  }
}
