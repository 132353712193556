:root[data-theme='light'] {
  --bush-collapse-bg-color: var(--primary-2);
}

:root[data-theme='dark'] {
  --bush-collapse-bg-color: rgba(26, 32, 44, 0.72);
}

.container {
  margin-bottom: 8px;

  &:last-of-type {
    margin: 0;
  }

  :global {
    .ant-collapse-item {
      background: var(--bush-collapse-bg-color);
      border-radius: 8px !important;
      padding: 16px 4px;

      .ant-collapse-header {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0;
        cursor: pointer;

        .ant-collapse-expand-icon {
          width: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
        }
      }

      .ant-collapse-header-text {
        width: 100%;
      }

      .ant-collapse-content-box {
        margin-top: 16px;
      }
    }
  }
}

.expandIcon {
  color: var(--contrast);
  transition: var(--transition);

  &Active {
    transform: rotate(180deg);
  }
}
