@use 'src/styles/typography';

:root[data-theme='light'] {
  --import-matching-page-column-header-border-color: rgba(0, 0, 0, 0.1);
}

:root[data-theme='dark'] {
  --import-matching-page-column-header-border-color: rgba(255, 255, 255, 0.05);
}

.wrapper {
  width: 100%;
  min-height: 56px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--import-matching-page-column-header-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--transition);
}

.title {
  @extend %h3;

  color: var(--contrast);
  text-align: center;
  transition: var(--transition);
}
