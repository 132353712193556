@use 'src/styles/typography';

.wrapper {
  width: 100%;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.text {
  @extend .caption;

  color: var(--contrast);
  opacity: 0.5;
}
