@import 'src/styles/typography';

.container {
  :global(.ant-select-dropdown) {
    padding: 4px 0 8px;
    background-color: var(--background-1);
    border-radius: 0 0 8px 8px;
    overflow: visible;
    margin-top: -4px;

    &::before {
      content: '';
      width: inherit;
      height: 4px;
      position: absolute;
      display: block;
      top: -4px;
      left: 0;
      background-color: var(--background-1);
    }
  }

  :global(.ant-select-dropdown-placement-topLeft),
  :global(.ant-select-dropdown-placement-topRight) {
    padding-top: 8px;
    padding-bottom: 4px;
    border-radius: 8px 8px 0 0;

    &::before {
      display: none;
    }

    &::after {
      content: '';
      width: inherit;
      height: 2px;
      position: absolute;
      display: block;
      bottom: -2px;
      left: 0;
      background-color: var(--background-1);
    }
  }

  :global(.ant-select-item:hover) {
    background-color: var(--select-option-hover-color);
  }

  :global(.ant-select-item-option-selected) {
    background-color: var(--brand-3);
  }

  :global(.ant-select-item-option-content) {
    @extend .body-m;
  }

  :global(.ant-select-item) {
    min-height: 28px;
    padding: 4px 16px;
    color: var(--contrast);
    transition: background-color var(--transition);
  }
}
