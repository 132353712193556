.wrapper {
  height: 64px;
  margin-bottom: 8px;
  padding-right: 16px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--primary-2);
}

.button {
  width: 36px;
  height: 28px;
}
