@import 'src/styles/typography';

:root[data-theme='light'] {
  --chart-page-background-color: var(--primary-1);
  --timeline-background-color: #ffffff;
}

:root[data-theme='dark'] {
  --chart-page-background-color: var(--primary-3);
  --timeline-background-color: transparent;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  background-color: var(--chart-page-background-color);
  overflow: hidden;
  touch-action: none;

  &_hidden {
    display: none;
  }
}

.timeline {
  height: 50px;
  min-height: 50px;
  margin-top: auto;
  padding-left: 16px;
  display: grid;
  grid-template-areas: 'periodButtons timeline';
  grid-template-columns: 264px auto;
  align-items: center;
  background-color: var(--timeline-background-color);

  &Wrapper {
    grid-area: timeline;
  }

  &PeriodsToggle {
    grid-area: periodButtons;
    width: 100%;

    & > .timelinePeriodButton {
      @extend .caption;

      padding: 6px 8px;
      flex-grow: 1;
    }
  }
}

.fullScreenContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--chart-page-background-color);
  z-index: 25;
}

.wellFormContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--chart-page-background-color);
  z-index: 25;
}
